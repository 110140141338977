import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { TPageInfo } from "@pages/dynamic";
import { defaultPageInstanceIndex, pageInstanceContext } from "@pages/dynamic/instanceIndexContext";
import {
    buildPageParserParam,
    buildQueryAsStr,
    gAdvDynPageName,
    parseQueryString,
} from "@utils/page-parser";
import { useRouter } from "next/router";
import { useContext, useMemo } from "react";

/**
 * Ein Hook, der ein Value + ValueChanged-Callback für ein Controlled Input Component bereitstellt.
 * @important Es müssen folgende Properties beim betroffenen Input Component gesetzt werden: ``value``, ``onValueChange``
 * @param value Standard-Wert oder ein State
 * @param onValueChanged Wird ausgeführt, wenn sich das Value durch den Benutzer ändert
 * @example
 * <TextField value={internalValue} onChange={onInternalChange}/>
 * <Checkbox checked={internalValue} onChange={onInternalChange}/>
 * @see Controlled vs. uncontrolled Component: https://blog.logrocket.com/controlled-vs-uncontrolled-components-in-react/
 */
export function useAdvRouter() {
    const router = useRouter();
    const instance = useContext(pageInstanceContext);

    const pageIndeces = useMemo(() => {
        const result: Array<string> = [];

        for (const k in router.query) {
            const posPageName = k.indexOf(gAdvDynPageName);
            if (posPageName > -1 && k != "_" + gAdvDynPageName) {
                result.push(k.substring(0, posPageName - 1));
            }
        }

        if (result.length == 0) result.push(defaultPageInstanceIndex);

        return result;
    }, [router.query]);

    const query = useMemo(() => {
        const result = JSON.parse(JSON.stringify(router.query));

        const instancePrefix = instance.index + "_";
        for (const k in result) {
            if (k.indexOf(instancePrefix) == 0) {
                result[k.substring(instancePrefix.length)] = result[k];
            }

            // TODO: 157870
            // Wenn wir im Designer sind, erstmal nicht die Werte rauslöschen (Workaround)
            // TODO: 161413
            // auch im Logincreen oder bei Token-Verwendung nicht löschen
            if (
                !router.pathname.startsWith("/designer/") &&
                !router.pathname.startsWith("/token") &&
                !(router.pathname == "/")
            )
                delete result[k];
        }

        return result;
    }, [instance.index, router.pathname, router.query]);

    const nextPageIndex = useMemo(() => {
        let maxIndex = 1;

        for (const item in pageIndeces) {
            const index = parseInt(pageIndeces[item]);
            if (maxIndex < index) {
                maxIndex = index;
            }
        }

        return (maxIndex + 1).toString();
    }, [pageIndeces]);

    const pageInfo: TPageInfo = useMemo(() => {
        return {
            pageInstanceIndex: instance.index,
            pathname: router.pathname,
            query: query,
        };
    }, [instance.index, query, router.pathname]);

    const removeInstance = useAdvCallback(
        (pageIndex: string) => {
            const queryCopy = JSON.parse(JSON.stringify(router.query));
            const instancePrefix = pageIndex + "_";

            for (const k in queryCopy) {
                if (k.indexOf(instancePrefix) == 0) {
                    delete queryCopy[k];
                }
            }
            const params = buildQueryAsStr(buildPageParserParam(queryCopy, []));
            router.push(router.pathname + params).catch(() => {});
        },
        [router],
    );

    const addInstanceToQuery = useAdvCallback(
        (queryString: string, pageIndex?: string) => {
            const urlSplit = router.asPath.split("?");

            if (pageIndex == undefined) pageIndex = pageInfo.pageInstanceIndex;

            let params = urlSplit[0];
            // Nur zusammenfassen, wenn es mehrere Instanzen gibt (erstmal nur Workaround)
            // TODO: 157870
            if (pageIndeces.length <= 1) params = "";
            else if (urlSplit.length > 1) params = urlSplit[1];

            const curQueryParse = parseQueryString(params);
            const newQuery = parseQueryString(queryString);

            for (const k in newQuery) {
                let instanceKeyName: string;
                if (k.indexOf(pageInfo.pageInstanceIndex + "_") == 0) instanceKeyName = k;
                else instanceKeyName = pageIndex + "_" + k;

                curQueryParse[instanceKeyName] = newQuery[k];
            }

            return buildQueryAsStr(buildPageParserParam(curQueryParse, []));
        },
        [pageIndeces.length, pageInfo.pageInstanceIndex, router.asPath],
    );

    const result = useMemo(() => {
        return {
            pageInfo,
            ...router,
            addInstanceToQuery,
            query,
            pageIndeces,
            nextPageIndex,
            removeInstance,
            origQuery: router.query,
        };
    }, [pageInfo, router, addInstanceToQuery, query, pageIndeces, nextPageIndex, removeInstance]);

    return result;
}
