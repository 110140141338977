import {
    IDataFields,
    IDataStructureFields,
    TAdvFilterSelection,
    TDataProviderFilterSort,
} from "@components/dynamic/data-provider/types";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import deepCopy from "@utils/deep-copy";
import { useMemo } from "react";
import { selectorFamily } from "recoil";

import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { TPageInfo } from "@pages/dynamic";
import {
    buildUniqueProviderID,
    dataproviderSelectedKeys,
    EDataproviderEvent,
    EDataProviderIssueType,
    EProviderServerInitState,
    recoilDataProviderClient,
    TExcelExportData,
    TProviderID,
} from "./data-provider-server";

const sequencesToLoad = 50;

const executeTransactions = (execFunc: () => void) => {
    setTimeout(execFunc, 0);
};

export const dpClientSetIsEditableGlobalTrans =
    (tb: TAdvTransactionInterface) =>
    (pageInfo: TPageInfo, providerName: string, isEditable: boolean) => {
        const providerID = buildUniqueProviderID(pageInfo, providerName);
        const providerLifetimeID = tb.get(
            recoilDataProviderClient.dataproviderLifetimeIDs(providerID),
        );

        const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
        tb.set(
            recoilDataProviderClient.dataproviderEvents,
            curEvents.concat({
                event: EDataproviderEvent.Editable,
                pageInfo,
                providerName: providerName,
                lifetimeID: providerLifetimeID,
                eventData: isEditable,
            }),
        );
    };

export const dpClientSetEditDatGlobalTrans =
    (tb: TAdvTransactionInterface) =>
    (pageInfo: TPageInfo, providerName: string, key: string, data: any, dataArrayIndex: number) => {
        const providerID = buildUniqueProviderID(pageInfo, providerName);
        const providerLifetimeID = tb.get(
            recoilDataProviderClient.dataproviderLifetimeIDs(providerID),
        );

        const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
        tb.set(
            recoilDataProviderClient.dataproviderEvents,
            curEvents.concat({
                event: EDataproviderEvent.SetEditData,
                pageInfo,
                providerName: providerName,
                lifetimeID: providerLifetimeID,
                eventData: { key, data, dataArrayIndex },
            }),
        );
    };

export const dpClientAddOrRemEditDatGlobalTrans =
    (tb: TAdvTransactionInterface) =>
    (pageInfo: TPageInfo, providerName: string, doAdd: boolean) => {
        const providerID = buildUniqueProviderID(pageInfo, providerName);
        const providerLifetimeID = tb.get(
            recoilDataProviderClient.dataproviderLifetimeIDs(providerID),
        );

        const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
        tb.set(
            recoilDataProviderClient.dataproviderEvents,
            curEvents.concat({
                event: EDataproviderEvent.AddOrRemEditData,
                pageInfo,
                providerName: providerName,
                lifetimeID: providerLifetimeID,
                eventData: { doAdd },
            }),
        );
    };

export enum EDataproviderClientOptions {
    // if a load event triggers implicity, then this load amount is used
    AutoLoadCount = 0,
    // if set to true, allows to get the provider fields even if they aren't loaded yet, which might decrease performance
    GetProviderFieldsWithoutData = 1,
}

// ### debug logging ###
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logInternal = (logText: string) => {
    // advlog("[dp-client] " + logText);
};

export const useDataproviderID = (providerName: string) => {
    const { pageInfo } = useAdvRouter();
    const providerID = useMemo(
        () => buildUniqueProviderID(pageInfo, providerName),
        [pageInfo, providerName],
    );

    return { pageInfo, providerID };
};

/**
 * Eine Hook-Gruppe mit diversen Funktionen, die typischerweise für einen Dataprovider benutzt werden.
 * Aus Performance Gründen sind die einzelnen Funktionen aufgeteilt.
 * Diese Hooks sollten möglichst immer verwendet werden, da einige der Funktionen sinnvolle
 * Logik bereitstellen, die z.B. einen Datensatz automatisch laden etc.
 * @param providerName Der Name des Providers, der benutzt werden soll
 * @returns Das Provider-Object mit diversen Provider-Funktionen, die benutzt werden können.
 *          Siehe die einzelnen Funktionen für detaliertere Dokumentation.
 */

export const useDataproviderLifetimeID = (providerID: TProviderID) => {
    const providerLifetimeID = useAdvRecoilValue(
        recoilDataProviderClient.dataproviderLifetimeIDs(providerID),
    );
    return providerLifetimeID;
};

const gAllowedStatesCompletenessExt = [
    EProviderServerInitState.HasDatastructure,
    EProviderServerInitState.HasData,
];
const gAllowedStatesCompleteness = [EProviderServerInitState.HasData];

const checkDataproviderCompletenessInternal =
    (tb: TAdvTransactionInterface) =>
    (pageInfo: TPageInfo, providerLifetimeID: string, providerName: string) => {
        const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
        const addEv = {
            event: EDataproviderEvent.CheckInit,
            pageInfo: pageInfo,
            providerName: providerName,
            lifetimeID: providerLifetimeID,
            eventData: undefined,
        };
        if (
            curEvents.length == 0 ||
            curEvents.find((ev) => JSON.stringify(ev) == JSON.stringify(addEv)) == undefined
        )
            tb.set(recoilDataProviderClient.dataproviderEvents, curEvents.concat(addEv));
    };

export const useDataproviderCheckCompleteness = (
    pageInfo: TPageInfo,
    providerID: TProviderID,
    providerName: string,
    providerLifetimeID: string,
    options?: Record<EDataproviderClientOptions, any>,
) => {
    const doesAllowFieldsWithoutData = useMemo(() => {
        if (
            options != undefined &&
            typeof options[EDataproviderClientOptions.GetProviderFieldsWithoutData] != "undefined"
        )
            return options[EDataproviderClientOptions.GetProviderFieldsWithoutData] as boolean;
        return false;
    }, [options]);

    const _providerInitState = useAdvRecoilValue(
        recoilDataProviderClient.providerInitStateGlobal(providerID),
    );

    const checkDataproviderCompletenessTrans = useAdvRecoilTransaction(
        checkDataproviderCompletenessInternal,
        [],
    );

    // internal function to check if a provider was correctly initialized
    const isDataproviderComplete = useMemo(() => {
        if (
            (doesAllowFieldsWithoutData
                ? gAllowedStatesCompletenessExt
                : gAllowedStatesCompleteness
            ).includes(_providerInitState)
        ) {
            return true;
        } else {
            return false;
        }
    }, [_providerInitState, doesAllowFieldsWithoutData]);

    const checkDataproviderCompleteness = useAdvCallback(() => {
        if (!isDataproviderComplete)
            executeTransactions(() =>
                checkDataproviderCompletenessTrans(pageInfo, providerLifetimeID, providerName),
            );
        return isDataproviderComplete;
    }, [
        isDataproviderComplete,
        checkDataproviderCompletenessTrans,
        pageInfo,
        providerLifetimeID,
        providerName,
    ]);

    return checkDataproviderCompleteness;
};

export const useDataproviderIsLoaded = (providerID: TProviderID) => {
    // ### dictonary ###
    const provider = useAdvRecoilValue(
        recoilDataProviderClient.getDataproviderDefinition(providerID),
    );

    /**
     * Gibt an ob die Dataprovider-Definition geladen ist.
     */
    const isLoaded = useAdvCallback(() => {
        return provider.IsLoaded();
    }, [provider]);

    return isLoaded;
};

export const useDataproviderIsEditable = (providerID: TProviderID) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _isEditable = useAdvRecoilValue(
        recoilDataProviderClient.providerEditableGlobal(providerID),
    );

    /**
     * Gibt an ob der Dataprovider editierbar ist.
     * @return Boolean der angibt ob der Dataprovider editierbar ist.
     */
    const isEditable = useAdvCallback(() => {
        logInternal("Getting provider isEditable");
        return _isEditable;
    }, [_isEditable]);

    return isEditable;
};

export const useDataproviderGetFields = (
    providerID: TProviderID,
    checkDataproviderCompleteness: ReturnType<typeof useDataproviderCheckCompleteness>,
) => {
    const _fields = useAdvRecoilValue(recoilDataProviderClient.providerFieldsGlobal(providerID));
    /**
     * Gibt die aktuellen Felder im Dataprovider zurück.
     * Sollten diese nicht existieren wird der Dataprovider initialisiert.
     * Wenn die Felder intern geändert werden, wird der State dieser Funktion geändert.
     * @return undefined, wenn der Dataprovider nicht initialisiert ist.
     *         Die Felder des Dataproviders sonst.
     */
    const getFields = useAdvCallback(() => {
        logInternal("Getting provider fields");
        // load an entry
        if (!checkDataproviderCompleteness()) {
            return undefined;
        } else {
            return _fields;
        }
    }, [checkDataproviderCompleteness, _fields]);

    return getFields;
};

const useDataproviderAutoLoadCount = (options?: Record<EDataproviderClientOptions, any>) => {
    const autoLoadAmount = useMemo(() => {
        if (
            options != undefined &&
            typeof options[EDataproviderClientOptions.AutoLoadCount] != "undefined"
        )
            return options[EDataproviderClientOptions.AutoLoadCount] as number;
        return 1;
    }, [options]);

    return autoLoadAmount;
};

const loadDataInternal =
    (tb: TAdvTransactionInterface) =>
    (
        startAt: number,
        loadCount: number,
        pageInfo: TPageInfo,
        providerLifetimeID: string,
        providerName: string,
    ) => {
        const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
        const addEv = {
            event: EDataproviderEvent.LoadData,
            pageInfo: pageInfo,
            providerName: providerName,
            lifetimeID: providerLifetimeID,
            eventData: { startAt: startAt, loadCount: loadCount },
        };
        //if (loadCount > 50 || loadCount <= 0)
        //    console.warn("MSC Client loadDataInternal", { startAt, loadCount, providerName });
        if (
            curEvents.length == 0 ||
            curEvents.find((ev) => JSON.stringify(ev) == JSON.stringify(addEv)) == undefined
        )
            tb.set(recoilDataProviderClient.dataproviderEvents, curEvents.concat(addEv));
    };

export const useDataproviderLoadData = (
    pageInfo: TPageInfo,
    providerName: string,
    providerLifetimeID: string,
) => {
    const loadDataTrans = useAdvRecoilTransaction(loadDataInternal, []);

    /**
     * Läd einen bestimmten Datensatz eines Dataproviders. Der State von @see getData und @see getAllData wird hierbei aktuallisiert.
     * Anmerkung: Es wird sollte davon ausgegangen werden, dass Sequenzen immer inorder abgefragt werden müssen
     * @param startSeq Gibt an welche Datensätze übersprungen werden
     * @param loadCount Gibt an wie viele Daten ab der Startsequenz geladen werden sollen
     */
    const loadData = useAdvCallback(
        (startSeq: number, loadCount: number = sequencesToLoad) => {
            logInternal(
                "Loading provider data at " +
                    startSeq.toString() +
                    ", amount " +
                    loadCount.toString() +
                    "",
            );
            //if (
            //    (loadCount > 50 || loadCount <= 0) &&
            //    providerName.trim() == "onNWuQT_zuyBF8a92EWRo"
            //)
            //    console.warn("MSC useDataproviderLoadData", startSeq, loadCount);
            loadDataTrans(startSeq, loadCount, pageInfo, providerLifetimeID, providerName);
        },
        [loadDataTrans, pageInfo, providerLifetimeID, providerName],
    );

    return loadData;
};

export const useDataproviderGetData = (
    providerID: TProviderID,
    checkDataproviderCompleteness: ReturnType<typeof useDataproviderCheckCompleteness>,
    loadData: ReturnType<typeof useDataproviderLoadData>,
    options?: Record<EDataproviderClientOptions, any>,
) => {
    const autoLoadAmount = useDataproviderAutoLoadCount(options);
    const _currentRecord = useAdvRecoilValue(dataproviderSelectedKeys([providerID]))[0];

    const _data = useAdvRecoilValue(recoilDataProviderClient.providerDataGlobal(providerID));

    // Helper function for getting & loading data
    const getDataInternal = useAdvCallback(
        (loadAllData: boolean) => {
            logInternal("Getting provider data");
            // load an entry, if there is non (will be queued if provider isn't loaded yet)
            if (Object.keys(_data).length == 0 || _currentRecord == undefined)
                loadData(0, loadAllData ? 0 : autoLoadAmount);
            // check completness
            if (!checkDataproviderCompleteness()) {
                return undefined;
            } else {
                return _data;
            }
        },
        [_data, _currentRecord, loadData, autoLoadAmount, checkDataproviderCompleteness],
    );

    /**
     * Gibt alle Datensätze des Dataproviders zurück.
     * Sollte es keine Daten geben, wird der Dataprovider versucht zu initialisiert und der erste Datensatz geladen.
     * Wenn neue Daten geladen werden wird der State dieser Funktion geändert. Also sollte diese Funktion als Dependency benutzt werden
     * @returns Die Datensätze oder undefined, wenn es keine gibt.
     */
    const getData = useAdvCallback(() => {
        return getDataInternal(false);
    }, [getDataInternal]);

    /**
     * Gibt alle Datensätze des Dataproviders zurück.
     * Sollte es keine Daten geben, wird der Dataprovider versucht zu initialisiert und alle Datensätze geladen.
     * Wenn neue Daten geladen werden wird der State dieser Funktion geändert. Also sollte diese Funktion als Dependency benutzt werden
     * @returns Die Datensätze oder undefined, wenn es keine gibt.
     */
    const getAllData = useAdvCallback(() => {
        return getDataInternal(true);
    }, [getDataInternal]);

    return { getData, getAllData };
};

export const useDataproviderGetRecords = (
    providerID: TProviderID,
    loadData: ReturnType<typeof useDataproviderLoadData>,
    options?: Record<EDataproviderClientOptions, any>,
) => {
    const autoLoadAmount = useDataproviderAutoLoadCount(options);
    const _currentRecord = useAdvRecoilValue(dataproviderSelectedKeys([providerID]))[0];

    /**
     * Respräsentiert alle Daten der aktuell ausgewählten Datensatze.
     * Sollte kein Datensatz ausgewählt sein, oder der Dataprovider nicht initialisiert sein,
     * dann wird dies' zuerst getan und undefined zurückgeliefert.
     * Werden die ausgewählten Records geändert triggered diese Funktion ein State-Change
     * @return Die aktuell ausgewählten Werte des aktuellen Datensatzes. Oder undefined, wenn es keinen aktuellen Datensatz gibt.
     *         Benutze den Record-Namen @see gDataproviderRecordIndexName auf einem Record,
     *         um den Index des Datensatzes innerhalb des Providers zu bekommen
     */
    const getCurrentRecords = useAdvCallback(() => {
        logInternal("Getting provider record");
        // load an entry
        if (_currentRecord == undefined) {
            loadData(0, autoLoadAmount);
        }
        return _currentRecord;
    }, [_currentRecord, loadData, autoLoadAmount]);

    return getCurrentRecords;
};

const getFieldsDataRecordsSelector = selectorFamily<
    { fields: IDataStructureFields; data: IDataFields; curRecords: any[] } | undefined,
    TProviderID
>({
    key: "dp_getFieldsDataRecordsSelector",
    get:
        (providerID) =>
        ({ get }) => {
            const initState = get(recoilDataProviderClient.providerInitStateGlobal(providerID));
            if (gAllowedStatesCompleteness.includes(initState)) {
                const fields = get(recoilDataProviderClient.providerFieldsGlobal(providerID));
                const data = get(recoilDataProviderClient.providerDataGlobal(providerID));
                const curRecords = get(dataproviderSelectedKeys([providerID]));
                if (fields != undefined && data != undefined && curRecords.length > 0) {
                    return { fields, data, curRecords: curRecords[0] };
                }
            }
            return undefined;
        },
});

/**
 * Optimized hook if you need exactly fields, data and records at once
 */
export const useDataproviderFieldsDataRecords = (
    providerID: TProviderID,
    pageInfo: TPageInfo,
    providerName: string,
    providerLifetimeID: string,
    loadAllData: boolean,
    options?: Record<EDataproviderClientOptions, any>,
) => {
    const autoLoadAmount = useDataproviderAutoLoadCount(options);
    const _fieldsDataRecords = useAdvRecoilValue(getFieldsDataRecordsSelector(providerID));

    const checkDataproviderCompletenessTrans = useAdvRecoilTransaction(
        checkDataproviderCompletenessInternal,
        [],
    );

    const loadDataTrans = useAdvRecoilTransaction(loadDataInternal, []);

    const fieldsDataRecords = useMemo(() => {
        logInternal("getting fields, data & records");
        executeTransactions(() => {
            checkDataproviderCompletenessTrans(pageInfo, providerLifetimeID, providerName);
            //if (
            //    providerName.trim() == "onNWuQT_zuyBF8a92EWRo" &&
            //    (loadAllData || autoLoadAmount <= 0)
            //)
            //    console.warn("MSC useDataproviderFieldsDataRecords", {
            //        loadAllData,
            //        autoLoadAmount,
            //    });
            loadDataTrans(
                0,
                loadAllData ? 0 : autoLoadAmount,
                pageInfo,
                providerLifetimeID,
                providerName,
            );
        });
        return _fieldsDataRecords;
    }, [
        _fieldsDataRecords,
        autoLoadAmount,
        checkDataproviderCompletenessTrans,
        loadAllData,
        loadDataTrans,
        pageInfo,
        providerLifetimeID,
        providerName,
    ]);

    return fieldsDataRecords;
};

export const useDataproviderSetEditData = (pageInfo: TPageInfo, providerName: string) => {
    const setEditDataInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => (key: string, data: any, dataArrayIndex: number) => {
            dpClientSetEditDatGlobalTrans(tb)(pageInfo, providerName, key, data, dataArrayIndex);
        },
        [pageInfo, providerName],
    );

    const setEditDataTrans = useAdvRecoilTransaction(setEditDataInternal, [setEditDataInternal]);
    /**
     * Setzt einen Feld-Wert im aktuellen Datensatz temporär auf den editierten Wert
     * @param key Name des Datensatz-Feldes
     * @param data Der neue Wert des Datensatz-Feldes
     */
    const setEditData = useAdvCallback(
        (key: string, data: any, dataArrayIndex: number) => {
            setEditDataTrans(key, data, dataArrayIndex);
        },
        [setEditDataTrans],
    );

    return setEditData;
};

export const useDataproviderIsEOF = (providerID: TProviderID) => {
    const _dataEOF = useAdvRecoilValue(recoilDataProviderClient.providerDataEOFGlobal(providerID));

    return _dataEOF;
};

export const useDataproviderSetCurrentRecords = (
    pageInfo: TPageInfo,
    providerID: TProviderID,
    providerName: string,
    providerLifetimeID: string,
) => {
    const isEditable = useDataproviderIsEditable(providerID);

    const setCurrentRecordsInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => (newRecord: number[]) => {
            const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
            tb.set(
                recoilDataProviderClient.dataproviderEvents,
                curEvents.concat({
                    event: EDataproviderEvent.SetRecords,
                    pageInfo,
                    providerName: providerName,
                    lifetimeID: providerLifetimeID,
                    eventData: [...newRecord],
                }),
            );
        },
        [pageInfo, providerLifetimeID, providerName],
    );
    const setCurrentRecordsTrans = useAdvRecoilTransaction(setCurrentRecordsInternal, [
        setCurrentRecordsInternal,
    ]);
    /**
     * Setzt die Datensatz-Zeiger des Providers auf die übergebenen Indices.
     * Sollte ein Index nicht auf einen existieren Datensatz zeigen wird dieser ignoriert.
     * Wenn keiner der Indices valide ist, passiert nichts.
     * Sollten die neuen Records den alten entsprechen wird der State-Change von getCurrentRecords nicht getriggered.
     * @param indices Ein Array von Datensatz-Indices, die ausgewählt werden sollen
     * @returns if the record was tried to be set
     */
    const setCurrentRecords = useAdvCallback(
        (indices: number[]) => {
            logInternal("Setting provider record");
            if (!isEditable()) {
                setCurrentRecordsTrans(indices);
                return true;
            } else {
                return false;
            }
        },
        [isEditable, setCurrentRecordsTrans],
    );

    return setCurrentRecords;
};

export const useDataprovider = (
    providerName: string,
    options?: Record<EDataproviderClientOptions, any>,
) => {
    const { pageInfo, providerID } = useDataproviderID(providerName);

    // ### Global states ###
    const providerLifetimeID = useDataproviderLifetimeID(providerID);
    const _filterOptions = useAdvRecoilValue(
        recoilDataProviderClient.dataproviderFilterOptionsGlobal(providerID),
    );
    const _dataEOF = useDataproviderIsEOF(providerID);
    const _errorsAndWarnings = useAdvRecoilValue(
        recoilDataProviderClient.dataproviderErrorsAndWarnings(providerID),
    );
    const _filter = useAdvRecoilValue(
        recoilDataProviderClient.dataproviderFilterGlobal(providerID),
    );

    // ### Transactions, don't mix transactions with normal functions to decrease dependencies and keep atomicy. They are synchronous ###
    const resetInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => () => {
            const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
            tb.set(
                recoilDataProviderClient.dataproviderEvents,
                curEvents.concat({
                    event: EDataproviderEvent.Reset,
                    pageInfo,
                    providerName: providerName,
                    lifetimeID: providerLifetimeID,
                    eventData: undefined,
                }),
            );
        },
        [pageInfo, providerLifetimeID, providerName],
    );

    const reloadInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => () => {
            const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
            tb.set(
                recoilDataProviderClient.dataproviderEvents,
                curEvents.concat({
                    event: EDataproviderEvent.Reload,
                    pageInfo,
                    providerName: providerName,
                    lifetimeID: providerLifetimeID,
                    eventData: undefined,
                }),
            );
        },
        [pageInfo, providerLifetimeID, providerName],
    );

    const resetSortInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => () => {
            const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
            tb.set(
                recoilDataProviderClient.dataproviderEvents,
                curEvents.concat({
                    event: EDataproviderEvent.ResetSort,
                    pageInfo,
                    providerName: providerName,
                    lifetimeID: providerLifetimeID,
                    eventData: undefined,
                }),
            );
        },
        [pageInfo, providerLifetimeID, providerName],
    );

    const clearDataInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => () => {
            const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
            tb.set(
                recoilDataProviderClient.dataproviderEvents,
                curEvents.concat({
                    event: EDataproviderEvent.ClearData,
                    pageInfo,
                    providerName: providerName,
                    lifetimeID: providerLifetimeID,
                    eventData: undefined,
                }),
            );
        },
        [pageInfo, providerLifetimeID, providerName],
    );

    type TSetFilterSort = (old: TDataProviderFilterSort[]) => TDataProviderFilterSort[];
    const setFilterSortInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => (newSort: TDataProviderFilterSort[] | TSetFilterSort) => {
            const curFilter = tb.get(recoilDataProviderClient.dataproviderFilterGlobal(providerID));
            const newFilter = deepCopy(curFilter);
            const newSorting =
                typeof newSort == "function" ? newSort(curFilter.sortColumns ?? []) : newSort;
            Object.assign(newFilter, {
                ...newFilter,
                filterValues: { ...curFilter.filterValues },
                sortColumns: newSorting,
            });
            const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
            tb.set(
                recoilDataProviderClient.dataproviderEvents,
                curEvents.concat({
                    event: EDataproviderEvent.SetFilter,
                    pageInfo,
                    providerName: providerName,
                    lifetimeID: providerLifetimeID,
                    eventData: newFilter,
                }),
            );
        },
        [pageInfo, providerID, providerLifetimeID, providerName],
    );

    type TSetFilterDispatch = (old: TAdvFilterSelection) => TAdvFilterSelection;
    const setFilterSelectionInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (newFilterSel: TAdvFilterSelection | TSetFilterDispatch) => {
                const curFilter = tb.get(
                    recoilDataProviderClient.dataproviderFilterGlobal(providerID),
                );
                const newFilter = deepCopy(curFilter);
                const newSel =
                    typeof newFilterSel == "function"
                        ? newFilterSel(curFilter.filterSelection ?? {})
                        : newFilterSel;
                Object.assign(newFilter, {
                    ...newFilter,
                    filterValues: { ...curFilter.filterValues },
                    filterSelection: newSel,
                });
                const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
                tb.set(
                    recoilDataProviderClient.dataproviderEvents,
                    curEvents.concat({
                        event: EDataproviderEvent.SetFilter,
                        pageInfo,
                        providerName: providerName,
                        lifetimeID: providerLifetimeID,
                        eventData: newFilter,
                    }),
                );
            },
        [pageInfo, providerID, providerLifetimeID, providerName],
    );

    const setSearchInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (newSearchText: string | undefined, listOfFieldsToSearch: string[]) => {
                const curFilter = tb.get(
                    recoilDataProviderClient.dataproviderFilterGlobal(providerID),
                );
                const newFilter = deepCopy(curFilter);
                Object.assign(newFilter, {
                    ...newFilter,
                    filteredText: newSearchText,
                    textFilterColumns: listOfFieldsToSearch,
                    filterValues: { ...curFilter.filterValues },
                });
                const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
                tb.set(
                    recoilDataProviderClient.dataproviderEvents,
                    curEvents.concat({
                        event: EDataproviderEvent.SetFilter,
                        pageInfo,
                        providerName: providerName,
                        lifetimeID: providerLifetimeID,
                        eventData: newFilter,
                    }),
                );
            },
        [pageInfo, providerID, providerLifetimeID, providerName],
    );

    const setFilterValueInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => (filterName: string, newFilterVal: string[]) => {
            const curFilter = tb.get(recoilDataProviderClient.dataproviderFilterGlobal(providerID));
            let filterValues =
                curFilter.filterValues != undefined ? { ...curFilter.filterValues } : undefined;
            if (filterValues == undefined) {
                filterValues = {};
            }
            Object.assign(filterValues, { ...filterValues, [filterName]: newFilterVal });
            const newFilter = JSON.parse(JSON.stringify(curFilter));
            Object.assign(newFilter, { ...newFilter, filterValues: filterValues });
            const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
            tb.set(
                recoilDataProviderClient.dataproviderEvents,
                curEvents.concat({
                    event: EDataproviderEvent.SetFilter,
                    pageInfo,
                    providerName: providerName,
                    lifetimeID: providerLifetimeID,
                    eventData: newFilter,
                }),
            );
        },
        [pageInfo, providerID, providerLifetimeID, providerName],
    );

    const downloadExcelInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => (excelData: TExcelExportData) => {
            const curEvents = tb.get(recoilDataProviderClient.dataproviderEvents);
            tb.set(
                recoilDataProviderClient.dataproviderEvents,
                curEvents.concat({
                    event: EDataproviderEvent.GetExcel,
                    pageInfo,
                    providerName: providerName,
                    lifetimeID: providerLifetimeID,
                    eventData: excelData,
                }),
            );
        },
        [pageInfo, providerLifetimeID, providerName],
    );

    const setIsEditableInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => (isEditable: boolean) => {
            dpClientSetIsEditableGlobalTrans(tb)(pageInfo, providerName, isEditable);
        },
        [pageInfo, providerName],
    );

    const setFilterSortTrans = useAdvRecoilTransaction(setFilterSortInternal, [
        setFilterSortInternal,
    ]);
    const setFilterSelectionTrans = useAdvRecoilTransaction(setFilterSelectionInternal, [
        setFilterSelectionInternal,
    ]);
    const setSearchTextTrans = useAdvRecoilTransaction(setSearchInternal, [setSearchInternal]);
    const setFilterValueTrans = useAdvRecoilTransaction(setFilterValueInternal, [
        setFilterValueInternal,
    ]);
    const clearDataTrans = useAdvRecoilTransaction(clearDataInternal, [clearDataInternal]);
    const resetTrans = useAdvRecoilTransaction(resetInternal, [resetInternal]);
    const reloadTrans = useAdvRecoilTransaction(reloadInternal, [reloadInternal]);
    const resetSortTrans = useAdvRecoilTransaction(resetSortInternal, [resetSortInternal]);
    const setIsEditableTrans = useAdvRecoilTransaction(setIsEditableInternal, [
        setIsEditableInternal,
    ]);
    const downloadExcelTrans = useAdvRecoilTransaction(downloadExcelInternal, [
        downloadExcelInternal,
    ]);

    const checkDataproviderCompleteness = useDataproviderCheckCompleteness(
        pageInfo,
        providerID,
        providerName,
        providerLifetimeID,
        options,
    );
    const isLoadedDef = useDataproviderIsLoaded(providerID);
    const isEditable = useDataproviderIsEditable(providerID);

    /**
     * Gibt an, ob der Dataprovider initialisiert wurde. Heißt, ob die Datenstruktur(Felder) geladen sind.
     * Wenn der Dataprovider geladen ist, wird der State dieser Funktion geändert.
     * Also sollte diese Funktion als Dependency benutzt werden
     * Anmerkung: Zu diesen Zeitpunk müssen @see getFields und @see getData kein undefined mehr zurückgeben dürfen.
     */
    const isLoaded = useAdvCallback(() => {
        if (!checkDataproviderCompleteness()) return false;
        return isLoadedDef();
    }, [checkDataproviderCompleteness, isLoadedDef]);

    /**
     * Setzt die aktuelle Filter-Sortierung des Dataproviders
     */
    const setFilterSort = useAdvCallback(
        (newSort: TDataProviderFilterSort[] | TSetFilterSort) => {
            logInternal("Setting provider filter sorting");
            if (!isEditable()) setFilterSortTrans(newSort);
        },
        [isEditable, setFilterSortTrans],
    );

    /**
     * Setzt die aktuelle Filter-Sortierung des Dataproviders
     */
    const setFilterSelection = useAdvCallback(
        (newFilterSel: TAdvFilterSelection | TSetFilterDispatch) => {
            logInternal("Setting provider filter selection");
            if (!isEditable()) setFilterSelectionTrans(newFilterSel);
        },
        [isEditable, setFilterSelectionTrans],
    );

    /**
     * Setzt den aktuellen Suchtext des Dataproviders
     */
    const setSearchText = useAdvCallback(
        (newSearchText: string | undefined, listOfFieldsToSearch: string[]) => {
            logInternal("Setting provider search text");
            if (!isEditable()) setSearchTextTrans(newSearchText, listOfFieldsToSearch);
        },
        [isEditable, setSearchTextTrans],
    );

    /**
     * Setzt den Wert eines Filter-Values
     * @param valueName Name des Filters
     * @param newFilter Neuer Wert des Filters, undefined, wenn der Wert gelöscht werden soll
     */
    const setFilterValue = useAdvCallback(
        (valueName: string, newFilter: string[]) => {
            logInternal("Setting provider filter");
            if (!isEditable()) setFilterValueTrans(valueName, newFilter);
        },
        [isEditable, setFilterValueTrans],
    );

    const setCurrentRecords = useDataproviderSetCurrentRecords(
        pageInfo,
        providerID,
        providerName,
        providerLifetimeID,
    );

    /**
     * Gibt die Sortierung im Filter zurück
     */
    const getSorting = useAdvCallback(() => {
        logInternal("Get provider filter sorting");
        return _filter.sortColumns == undefined
            ? []
            : _filter.sortColumns.map((val) => {
                  return { name: val.name, sortDesc: val.desc, isConst: val.isConst ?? false };
              });
    }, [_filter.sortColumns]);

    /**
     * Gibt die selections im Filter zurück
     */
    const getFilterSelection = useAdvCallback(() => {
        logInternal("Get provider filter selections");
        return _filter.filterSelection == undefined ? {} : _filter.filterSelection;
    }, [_filter.filterSelection]);

    /**
     * Gibt den Suchtext des Dataproviders zurück
     */
    const getSearchText = useAdvCallback(() => {
        logInternal("Get provider filter sorting");
        return _filter.filteredText;
    }, [_filter.filteredText]);

    const clearData = useAdvCallback(() => {
        logInternal("Clearing provider data");
        clearDataTrans();
    }, [clearDataTrans]);
    const reset = useAdvCallback(() => {
        logInternal("Resetting provider");
        resetTrans();
    }, [resetTrans]);
    const reload = useAdvCallback(() => {
        logInternal("Reloading provider");
        reloadTrans();
    }, [reloadTrans]);
    const resetSort = useAdvCallback(() => {
        logInternal("Resetting provider's sorting");
        resetSortTrans();
    }, [resetSortTrans]);

    const getFields = useDataproviderGetFields(providerID, checkDataproviderCompleteness);

    /**
     * Macht den Dataprovider editierbar
     * @param isEditable Ein Boolean das den neuen Editierbarkeits-Zustand angibt
     */
    const setIsEditable = useAdvCallback(
        (isEditable: boolean) => {
            setIsEditableTrans(isEditable);
        },
        [setIsEditableTrans],
    );

    const setEditData = useDataproviderSetEditData(pageInfo, providerName);

    const loadData = useDataproviderLoadData(pageInfo, providerName, providerLifetimeID);

    /**
     * Download the current tables as excel
     */
    const downloadExcel = useAdvCallback(
        (excelData: TExcelExportData) => {
            downloadExcelTrans(excelData);
        },
        [downloadExcelTrans],
    );

    const { getData, getAllData } = useDataproviderGetData(
        providerID,
        checkDataproviderCompleteness,
        loadData,
        options,
    );

    const getCurrentRecords = useDataproviderGetRecords(providerID, loadData, options);

    /**
     * Gibt alle Filter-Optionen des Dataproviders zurück, die der Server (durch die Datasource) für diesen Provider bereitstellt.
     * Wenn neue Filter-Optionen geladen werden wird der State dieser Funktion geändert. Also sollte diese Funktion als Dependency benutzt werden
     * @return Alle möglichen Filter-Optionen
     */
    const getFilterOptions = useAdvCallback(() => {
        logInternal("Getting provider filter options");
        return _filterOptions;
    }, [_filterOptions]);

    /**
     * Gibt an, ob der Dataprovider keine weiteren Daten laden kann.
     * Wenn keine neuen Daten mehr geladen werden können, wird der State dieser Funktion geändert.
     * Also sollte diese Funktion als Dependency benutzt werden
     * @returns true, wenn es keine weiteren Daten zum Laden gibt. Sonst false.
     */
    const isEOF = useAdvCallback(() => {
        return _dataEOF.isEOF;
    }, [_dataEOF.isEOF]);

    /**
     * Überprüft, ob der Dataprovider Fehler verursacht hat
     * Kann als Dependency benutzt werden um auf Fehler zu warten.
     */
    const hasErrors = useAdvCallback(() => {
        return (
            _errorsAndWarnings.find((val) => val.type == EDataProviderIssueType.Error) != undefined
        );
    }, [_errorsAndWarnings]);

    /**
     * Überprüft, ob der Dataprovider Warnungen verursacht hat
     * Kann als Dependency benutzt werden um auf Warnungen zu warten.
     */
    const hasWarnings = useAdvCallback(() => {
        return (
            _errorsAndWarnings.find((val) => val.type == EDataProviderIssueType.Warning) !=
            undefined
        );
    }, [_errorsAndWarnings]);

    /**
     * Gibt alle aktuellen Fehler des Dataproviders zurück
     * Kann als Dependency benutzt werden um auf Fehler zu warten.
     * @return Ein Array von Fehlern
     */
    const getErrors = useAdvCallback(() => {
        return _errorsAndWarnings
            .filter((val) => val.type == EDataProviderIssueType.Error)
            .map((val) => val.msg);
    }, [_errorsAndWarnings]);

    /**
     * Gibt alle aktuellen Warnungen des Dataproviders zurück
     * Kann als Dependency benutzt werden um auf Warnungen zu warten.
     * @return Ein Array von Warnungen
     */
    const getWarnings = useAdvCallback(() => {
        return _errorsAndWarnings
            .filter((val) => val.type == EDataProviderIssueType.Warning)
            .map((val) => val.msg);
    }, [_errorsAndWarnings]);

    const thisProvider = useMemo(() => {
        logInternal("Rebuilding provider");
        return {
            isLoaded,
            isEOF,

            isEditable,
            setIsEditable,
            setEditData,

            hasErrors,
            getErrors,
            hasWarnings,
            getWarnings,

            loadData,
            clearData,
            reset,
            reload,
            resetSort,

            getAllData,
            getData,
            getFields,

            downloadExcel,

            getCurrentRecords,
            setCurrentRecords,

            getFilterOptions,

            setFilterSort,
            setFilterSelection,
            getSorting,
            getFilterSelection,
            setSearchText,
            getSearchText,
            setFilterValue,
        };
    }, [
        isLoaded,
        isEOF,
        isEditable,
        setIsEditable,
        setEditData,
        hasErrors,
        getErrors,
        hasWarnings,
        getWarnings,
        loadData,
        clearData,
        reset,
        reload,
        resetSort,
        getAllData,
        getData,
        getFields,
        downloadExcel,
        getCurrentRecords,
        setCurrentRecords,
        getFilterOptions,
        setFilterSort,
        setFilterSelection,
        getSorting,
        getFilterSelection,
        setSearchText,
        getSearchText,
        setFilterValue,
    ]);

    return thisProvider;
};
