import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeCustom } from "@feature/Designer/types/component-type";
import { IDesignableProperty } from "@feature/Designer/types/designable";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { toAdvText } from "@hooks/language/useTranslation";
import { TableIcon } from "@themes/icons";

const baseProps = [
    AdvProperty.Text.create(
        toAdvText({ text: "traeArtiListReader" }),
        "traeArtiListReader",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "traeArtiListReader" }),
        "",
    ),
    AdvProperty.Text.create(
        toAdvText({ text: "traeApplArtListReader" }),
        "traeApplArtListReader",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "traeApplArtListReader" }),
        "",
    ),
    AdvProperty.Text.create(
        // Daten zum Server
        toAdvText({ text: "traeArtiListWriter" }),
        "traeArtiListWriter",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "traeArtiListWriter" }),
        "",
    ),
    AdvProperty.Text.create(
        // Verfügbare KdArtis
        toAdvText({ text: "kdArtiListReader" }),
        "kdArtiListReader",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "kdArtiListReader" }),
        "",
    ),
    AdvProperty.Text.create(
        // Verfügbare Artikelgrößen zu den KdArti
        toAdvText({ text: "artGroeListReader" }),
        "artGroeListReader",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "artGroeListReader" }),
        "",
    ),
    AdvProperty.Text.create(
        // Verfügbare Platzierungen
        toAdvText({ text: "platzListReader" }),
        "platzListReader",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "platzListReader" }),
        "",
    ),
    AdvProperty.Text.createRecoilSelectProvider(
        toAdvText({ text: "providerKey" }),
        "providerKey",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "providerKey" }),
    ),
    AdvProperty.Boolean.create(
        toAdvText({ text: "canAddArticle" }),
        "canAddArticle",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "canAddArticle" }),
        true,
    ),
    AdvProperty.Boolean.create(
        toAdvText({ text: "canRemoveArticle" }),
        "canRemoveArticle",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "canRemoveArticle" }),
        true,
    ),
    ...AdvCommonComponentAttributes,
    ...AdvThemeProviderProperties,
    ...AdvStackItemDesignable.CommonProperties,
    ...AdvGridItemDesignable.CommonProperties,
];

const anprobeProps = [
    AdvProperty.Text.create(
        toAdvText({ text: "traeApplListReader" }),
        "traeApplListReader",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "traeApplListReader" }),
        "",
    ),
    AdvProperty.Boolean.create(
        toAdvText({ text: "isEditMode" }),
        "isEditMode",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "isEditMode" }),
        true,
    ),
    AdvProperty.Text.create(
        toAdvText({ text: "anprobeMemoReader" }),
        "anprobeMemoReader",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "anprobeMemoReader" }),
        "",
    ),
    AdvProperty.Text.create(
        toAdvText({ text: "anprobeMemoWriter" }),
        "anprobeMemoWriter",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "anprobeMemoWriter" }),
        "",
    ),
];

registerDesignableComponent({
    staticData: {
        name: LAN.ANPROBE_ART_LIST.text,

        type: EComponentTypeCustom.TraeAnprArtList,
        supportsChildren: false,
        category: DefaultComponentCategory.Misc,
        icon: TableIcon,
    },
    properties: [...baseProps, ...anprobeProps],
    propertiesBuilders: [],
    presets: [],
});

const anlageProps: IDesignableProperty[] = [
    AdvProperty.Text.create(
        toAdvText({ text: "groupsListReader" }),
        "groupsListReader",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "groupsListReader" }),
        "",
    ),
    AdvProperty.Boolean.create(
        toAdvText({ text: "hideMengenInput" }),
        "hideMengenInput",
        toAdvText(LAN.GENERAL),
        toAdvText({ text: "hideMengenInput" }),
        false,
    ),
];
registerDesignableComponent({
    staticData: {
        name: LAN.TRAEARTI_ANLAGE_LIST.text,

        type: EComponentTypeCustom.TraeArtiAnlageList,
        supportsChildren: false,
        category: DefaultComponentCategory.Misc,
        icon: TableIcon,
    },
    properties: [...baseProps, ...anlageProps],
    propertiesBuilders: [],
    presets: [],
});
