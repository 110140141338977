import { _copyAndSort } from "@components/data/table/utils";
import {
    EFieldSettingsFieldTypes,
    IAdvDataProvider,
    IDataFields,
    IDataProviderRecordResponse,
    IDataProviderRecordResponseData,
    IDataStructureFields,
    IPublicFilterCategory,
    TAdvFilterSelection,
    TAdvFilterValues,
    TDataProviderFilter,
    TDataProviderSocketGetStructureAndDataResponse,
} from "@components/dynamic/data-provider/types";
import { gAdvParameterMappingKey } from "@components/dynamic/parameter-mapping/types";
import { TAdvDropdownItem } from "@components/inputs/dropdown";
import { replaceDatasourcePrefix } from "@data/designer/file";
import { buildPageIDForVariableID, recoilPageVariables, recoilParameters } from "@data/parameters";
import { CreateRecoilDictionary } from "@data/utils/recoil-dictionary";
import useIsMounted from "@hooks/misc/useIsMounted";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import { createExcelFile } from "@hooks/useAdvExcel";
import { TSuccessClass, useAdvSocketCallback } from "@hooks/useAdvSocketCallback";
import { TPageInfo } from "@pages/dynamic";
import { defaultPageInstanceIndex } from "@pages/dynamic/instanceIndexContext";
import { trans_assert } from "@utils/assert-trans";
import { ServerStrToLocalDateStr, ServerStrToLocalDateTimeStr } from "@utils/date";
import deepCopy from "@utils/deep-copy";
import { advcatch, advlog } from "@utils/logging";
import { buildUniquePageID, gAdvDesignerPageName, getQueryValue } from "@utils/page-parser";
import { nanoid } from "nanoid";
import { useRef } from "react";
import {
    DefaultValue,
    RecoilValueReadOnly,
    atom,
    atomFamily,
    selectorFamily,
    useRecoilTransactionObserver_UNSTABLE,
} from "recoil";

// TODO: move this somewhere else
// keep in sync with server
export const gDataproviderConstFilterValueMappingKey = "__ConstFilterValueMapping__ADV";
export const gDataproviderConstFilterValueMappingPageKey = "__Page_ConstFilterValueMapping__ADV";

export const gDataproviderDynFilterValueMappingKey = "__DynFilterValueMapping__ADV";

/**
 * Der Name des Object-Records, welches den Index des ausgewählten Records entspricht (z.B. index = 1 == 2ter Datensatz in der Liste)
 */
export const gDataproviderRecordIndexName = "__ADV_Dataprovider_Index_Record_Name__";

/**
 * @see buildUniqueProviderID
 */
export type TProviderID = { id: string; name: string; pageid: string };
const { ...dictionary } = CreateRecoilDictionary<IAdvDataProvider, TProviderID>("dataprovider");

export function buildPageIDForUIDesigner(activeFileName: string) {
    const pageInfo: TPageInfo = {
        pageInstanceIndex: defaultPageInstanceIndex,
        pathname: "/designer/ui",
        query: { [gAdvDesignerPageName]: activeFileName },
    };
    return buildUniquePageID(pageInfo);
}

export function buildPageIDForProviderID(pageInfo: TPageInfo) {
    const zwPageInfo = JSON.parse(JSON.stringify(pageInfo));
    zwPageInfo.query = pageInfo.pathname.startsWith("/designer/")
        ? { [gAdvDesignerPageName]: getQueryValue(pageInfo.query, gAdvDesignerPageName) }
        : pageInfo.query;
    return buildUniquePageID(zwPageInfo);
}

export function buildUniqueProviderIDForUIDesigner(
    activeFileName: string,
    providerName: string,
): TProviderID {
    const pageID = buildPageIDForUIDesigner(activeFileName);
    return {
        id: pageID + providerName,
        name: providerName,
        pageid: pageID,
    };
}

/**
 * Generates an unique ID for a dataprovider
 * @param pathname the current page
 * @param query the current query of the page, note for designer this parameter only parses the active file, the rest is ignored
 * @param providerName the name of the provider
 * @returns an unique identifier for the provider
 */
export function buildUniqueProviderID(pageInfo: TPageInfo, providerName: string): TProviderID {
    const pageID = buildPageIDForProviderID(pageInfo);
    return {
        id: pageID + providerName,
        name: providerName,
        pageid: pageID,
    };
}

// ######################## Global atoms ############################
export enum EProviderServerInitState {
    NotInitialized = 0,
    Initialized,
    HasDatastructure,
    HasData,
}
const providerInitStateGlobal = atomFamily<EProviderServerInitState, TProviderID>({
    key: "providerIsInitializedGlobal",
    default: EProviderServerInitState.NotInitialized,
});

const providerDataGlobal = atomFamily<IDataFields, TProviderID>({
    key: "providerDataGlobal",
    default: {},
});

const providerEditableGlobal = atomFamily<boolean, TProviderID>({
    key: "providerEditableGlobal",
    default: false,
});

const providerEditDataGlobal = atomFamily<Record<string, any>[], TProviderID>({
    key: "providerEditDataGlobal",
    default: [{}],
});

const providerDataEOFGlobal = atomFamily<{ isEOF: boolean; seqEOF: number }, TProviderID>({
    key: "providerDataEOFGlobal",
    default: { isEOF: false, seqEOF: -1 },
});

const providerDataLoadLenGlobal = atomFamily<number, TProviderID>({
    key: "providerDataLoadLenGlobal",
    default: 0,
});

const providerFieldsGlobal = atomFamily<IDataStructureFields, TProviderID>({
    key: "providerFieldsGlobal",
    default: {},
});

const dataproviderFilterGlobal = atomFamily<TDataProviderFilter, TProviderID>({
    key: "dataproviderFilterGlobal",
    default: {},
});

const dataproviderFilterOptionsGlobal = atomFamily<Array<IPublicFilterCategory>, TProviderID>({
    key: "dataproviderfilterOptionsGlobal",
    default: [],
});

const dataproviderSelectedValueGlobal = atomFamily<number[], TProviderID>({
    key: "dataproviderSelectedValueGlobal",
    default: undefined,
});

/**
 * Ein Enum, dass verschiedene Probleme, die im Dataprovider auftreten können, typisiert.
 */
export enum EDataProviderIssueType {
    Warning = 0,
    Error,
}

const dataproviderErrorsAndWarnings = atomFamily<
    Array<{ type: EDataProviderIssueType; msg: string }>,
    TProviderID
>({
    key: "dataproviderErrorsAndWarnings",
    default: [],
});

export type TExcelExportData = {
    fieldNames: { FieldName: string; DisplayName: string }[];
    sheetName: string;
    fileName: string;
};

/**
 * Die Event-Typen, die der Dataprovider senden kann und vom Dataprovider-Server akzeptiert werden.
 */
export enum EDataproviderEvent {
    Reset = 0,
    Reload, // also tells the server to reset the dp
    ResetSort, // also tells the server to remove the cache entry
    ClearData,
    CheckInit,
    SetFilter,
    LoadData,
    SetRecords,
    Editable,
    SetEditData,
    AddOrRemEditData,
    GetExcel,
}

type TProviderEvent = {
    event: EDataproviderEvent;
    pageInfo: TPageInfo;
    providerName: string;
    lifetimeID: string;
    eventData: any;
};
const dataproviderEvents = atom<Array<TProviderEvent>>({ key: "dataproviderEvents", default: [] });

const dataproviderLifetimeIDs = atomFamily<string, TProviderID>({
    key: "dataproviderLifetimeIDs",
    default: () => nanoid(),
});

const dataproviderLoadDataRequests = atomFamily<
    Array<{ lifetimeID: string; startAt: number; loadCount: number }>,
    TProviderID
>({
    key: "dataproviderLoadDataRequests",
    default: [],
});

const MAX_DATA_LEN = 2147483647 - 1024; // small pufferzone

// ##################### dataprovider server hook #########################
/**
 * Ein Hook, der sich um die Dataprovider-Client-Events kümmert und dafür sorgt, dass
 * sich Daten nicht dublizieren, Daten über das Socket gesendet werden etc.
 */
export const useDataproviderServer = (isDesigner: boolean = false) => {
    const { sendCallbackRequestTrans } = useAdvSocketCallback();

    const { isOrWillMount } = useIsMounted();

    const { reset, resetInternal } = useProvider();

    const resetSortInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pageInfo: TPageInfo, providerName: string, provider: IAdvDataProvider) => {
                resetInternal(tb)(pageInfo, providerName, provider, true, true);
            },
        [resetInternal],
    );
    const resetSort = useAdvRecoilTransaction(resetSortInternal, [resetSortInternal]);

    const clearDataInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => (pageInfo: TPageInfo, providerName: string) => {
            const providerID = buildUniqueProviderID(pageInfo, providerName);
            const isProvInit = [
                EProviderServerInitState.HasDatastructure,
                EProviderServerInitState.HasData,
            ].includes(tb.get(providerInitStateGlobal(providerID)));
            if (isProvInit) {
                // set default fields again
                const fieldKeys = Object.keys(tb.get(providerFieldsGlobal(providerID)));
                const dataObj: IDataFields = {};
                for (const fieldKey of fieldKeys) {
                    Object.assign(dataObj, { ...dataObj, [fieldKey]: { Values: [] } });
                }
                tb.set(providerDataGlobal(providerID), dataObj);
                tb.set(
                    providerInitStateGlobal(providerID),
                    EProviderServerInitState.HasDatastructure,
                );

                tb.reset(providerDataEOFGlobal(providerID));
                tb.reset(providerDataLoadLenGlobal(providerID));
                tb.reset(dataproviderLoadDataRequests(providerID));
                tb.reset(dataproviderSelectedValueGlobal(providerID));
                // don't allow old request to pass
                tb.set(dataproviderLifetimeIDs(providerID), nanoid());
            }
        },
        [],
    );

    const loadDataStructureInternalSocket = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (
                pageInfo: TPageInfo,
                providerName: string,
                lifetimeID: string,
                resolve: (
                    lifetimeID: string,
                    paramRes: TDataProviderSocketGetStructureAndDataResponse,
                ) => void,
            ) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                const provIsInit = tb.get(providerInitStateGlobal(providerID));
                if (provIsInit == EProviderServerInitState.Initialized) {
                    sendCallbackRequestTrans(tb)(
                        function (lifetimeID: string, res: any) {
                            resolve(
                                lifetimeID,
                                res as TDataProviderSocketGetStructureAndDataResponse,
                            );
                        }.bind(null, lifetimeID),
                        "dataprovider",
                        "structure_and_data",
                        {
                            ProviderName: providerName,
                            PageIndex: pageInfo.pageInstanceIndex,
                        },
                    );
                }
            },
        [sendCallbackRequestTrans],
    );

    const initDataproviderInternalSocket = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            function (
                pageInfo: TPageInfo,
                providerName: string,
                resolve: (resSuccess: boolean) => void,
                resolveTrans: (
                    tb: TAdvTransactionInterface,
                ) => (
                    pageInfo: TPageInfo,
                    providerName: string,
                    lifetimeID: string,
                    resSuccess: boolean,
                ) => void,
            ) {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                const provInitState = tb.get(providerInitStateGlobal(providerID));
                if (provInitState == EProviderServerInitState.NotInitialized) {
                    tb.set(
                        providerInitStateGlobal(providerID),
                        EProviderServerInitState.Initialized,
                    );

                    if (isDesigner) {
                        // prepare const filter values
                        const variableID = buildPageIDForVariableID(pageInfo);
                        const valueFilters: TAdvFilterValues = {};
                        const pageParams = tb.get(recoilParameters(variableID));
                        const providerDef = tb.get(dictionary.values(providerID));
                        const filterParamMappingKeys = Object.keys(
                            providerDef.Get().constFilterValueMapping,
                        );
                        const pageParamsKeys = Object.keys(pageParams.params);
                        const pageParamValuesCur = tb.get(recoilPageVariables(variableID));
                        const pageParamValues = new Map<string, string | string[]>();
                        if (pageParams != undefined) {
                            const paramKeys = Object.keys(pageParams.params);
                            const paramList: string[] = [];
                            for (const paramKey of paramKeys) {
                                const pageParamName = pageParams.params[paramKey].value;
                                const pageParamOptions = pageParams.params[paramKey].options;
                                const pageParamOptionsKeys = Object.keys(pageParamOptions);
                                const pageParamVal = pageParamValuesCur.find(
                                    (val) => val.pageParamName == pageParamName,
                                );
                                if (
                                    !paramList.includes(pageParamName) &&
                                    paramKey.startsWith(gAdvParameterMappingKey) &&
                                    pageParamVal != undefined &&
                                    pageParamOptionsKeys.includes("constant")
                                ) {
                                    pageParamValues.set(pageParamName, pageParamVal.value);
                                    paramList.push(pageParamName);
                                }
                            }
                        }

                        let filterMapIndex = 0;
                        while (
                            filterParamMappingKeys.includes(
                                gDataproviderConstFilterValueMappingPageKey +
                                    filterMapIndex.toString(),
                            ) &&
                            filterParamMappingKeys.includes(
                                gDataproviderConstFilterValueMappingKey + filterMapIndex.toString(),
                            )
                        ) {
                            const curFilterParam =
                                providerDef.Get().constFilterValueMapping[
                                    gDataproviderConstFilterValueMappingKey +
                                        filterMapIndex.toString()
                                ];
                            const curPageParam =
                                providerDef.Get().constFilterValueMapping[
                                    gDataproviderConstFilterValueMappingPageKey +
                                        filterMapIndex.toString()
                                ];

                            let pageParamIndex = 0;
                            while (
                                pageParamsKeys.includes(
                                    gAdvParameterMappingKey + pageParamIndex.toString(),
                                )
                            ) {
                                if (
                                    pageParams.params[
                                        gAdvParameterMappingKey + pageParamIndex.toString()
                                    ].value == curPageParam
                                ) {
                                    const isRequired = false;
                                    /*
                                    This would disallow the designer to load data if the required
                                    page variable was not found
                                    const pageParamOptions =
                                        pageParams.params[
                                            gAdvParameterMappingKey + pageParamIndex.toString()
                                        ].options;

                                    const pageParamOptionKeys = Object.keys(pageParamOptions);

                                    if (
                                        pageParamOptionKeys.includes("required") &&
                                        pageParamOptions["required"] === true
                                    ) {
                                        isRequired = true;
                                    }
                                    */

                                    if (pageParamValues.has(curPageParam)) {
                                        const pageParam = pageParamValues.get(curPageParam);
                                        if (pageParam != undefined)
                                            Object.assign(valueFilters, {
                                                ...valueFilters,
                                                [curFilterParam]: pageParam,
                                            });
                                    } else if (isRequired) {
                                        const curWarningsAndErrors = tb.get(
                                            dataproviderErrorsAndWarnings(providerID),
                                        );
                                        tb.set(
                                            dataproviderErrorsAndWarnings(providerID),
                                            curWarningsAndErrors.concat({
                                                type: EDataProviderIssueType.Error,
                                                msg:
                                                    "Could not find required page variable: '" +
                                                    curPageParam +
                                                    "'",
                                            }),
                                        );
                                    }
                                }
                                ++pageParamIndex;
                            }
                            ++filterMapIndex;
                        }

                        sendCallbackRequestTrans(tb)(
                            function (resStreamID: any) {
                                resolve((resStreamID as TSuccessClass).Success);
                            },
                            "page",
                            "initdataprovider_designer",
                            {
                                Name: providerName,
                                DatasourceName: providerDef.Get().datasourceName,
                                ConstFilterValues: Object.keys(valueFilters).map((val) => {
                                    return {
                                        Name: val,
                                        Values: Array.isArray(valueFilters[val])
                                            ? [...valueFilters[val]]
                                            : [valueFilters[val]],
                                    };
                                }),
                            },
                        );
                    } else {
                        resolveTrans(tb)(
                            pageInfo,
                            providerName,
                            tb.get(dataproviderLifetimeIDs(providerID)),
                            true,
                        );
                    }
                }
            },
        [isDesigner, sendCallbackRequestTrans],
    );

    const prepareDataFetching = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (
                pageInfo: TPageInfo,
                providerName: string,
                startAt: number,
                loadCount: number,
                sendImpl: (dataIn: any, resolve: (value: any) => void) => void,
                extraSendInfo: () => object,
            ) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);

                const sorting: Array<{
                    fieldName: string;
                    sortDesc: boolean;
                }> = [];

                const currentFilter = tb.get(dataproviderFilterGlobal(providerID));

                if (typeof currentFilter.sortColumns != "undefined") {
                    for (const sort of currentFilter.sortColumns) {
                        sorting.push({
                            fieldName: sort.name,
                            sortDesc: sort.desc,
                        });
                    }
                }

                const requestPromise = new Promise<any>((resolve) => {
                    const selection: Array<{ name: string; selection: number }> = [];

                    for (const k in currentFilter.filterSelection) {
                        selection.push({
                            name: k,
                            selection: currentFilter.filterSelection[k],
                        });
                    }

                    const filterValues: Array<{ Name: string; Values: string[] }> = [];

                    for (const filterVal in currentFilter.filterValues) {
                        filterValues.push({
                            Name: filterVal,
                            Values: [...currentFilter.filterValues[filterVal]],
                        });
                    }

                    const dataIn = {
                        ProviderName: providerName,
                        PageIndex: pageInfo.pageInstanceIndex,
                        Options: {
                            StartAt: startAt,
                            Length: loadCount,
                            Search: currentFilter.filteredText ?? "",
                            SearchFields: currentFilter.textFilterColumns ?? [],
                            Sort: sorting,
                            TableFilter:
                                currentFilter.customFilteredFields != undefined
                                    ? currentFilter.customFilteredFields.map((val) => {
                                          return {
                                              CustomField: val.FieldName,
                                              CustomValue: val.FieldValue,
                                          };
                                      })
                                    : [],
                            FilterOptions: selection,
                            FilterValues: filterValues,
                        },
                        ...extraSendInfo(),
                    };
                    sendImpl(dataIn, resolve);
                });

                return requestPromise;
            },
        [],
    );

    // ### data loading ###
    const loadDataInternalSocket = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pageInfo: TPageInfo, providerName: string, startAt: number, loadCount: number) => {
                trans_assert(startAt >= 0, "Provide a start index that is >= 0.");

                return prepareDataFetching(tb)(
                    pageInfo,
                    providerName,
                    startAt,
                    loadCount,
                    (dataIn, resolve) => {
                        sendCallbackRequestTrans(tb)(
                            (data: IDataProviderRecordResponse) => {
                                const response: IDataProviderRecordResponseData = JSON.parse(
                                    data.dataJSON,
                                );
                                resolve(response);
                            },
                            "dataprovider",
                            "data",
                            dataIn,
                        );
                    },
                    () => {
                        return {};
                    },
                );
            },
        [prepareDataFetching, sendCallbackRequestTrans],
    );

    // ### excel export ###
    const loadExcelData = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pageInfo: TPageInfo, providerName: string, excelData: TExcelExportData) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                const fields = tb.get(providerFieldsGlobal(providerID));
                prepareDataFetching(tb)(
                    pageInfo,
                    providerName,
                    0,
                    MAX_DATA_LEN,
                    (dataIn, resolve) => {
                        sendCallbackRequestTrans(tb)(
                            (data: IDataProviderRecordResponse) => {
                                const response: IDataProviderRecordResponseData = JSON.parse(
                                    data.dataJSON,
                                );
                                resolve(response);
                            },
                            "dataprovider",
                            "excel_data",
                            dataIn,
                        );
                    },
                    () => {
                        return { FieldNames: excelData.fieldNames.map((f) => f.FieldName) };
                    },
                )
                    .then((excelFileBinary: IDataProviderRecordResponseData) => {
                        //hier werden die erhaltenen Excel-Daten aufbereitet und dann zur Dateierstellung weitergegeben
                        const data: any[][] = [];
                        const colSize: Array<number> = [];
                        for (const fieldName of excelData.fieldNames) {
                            const field = fieldName.FieldName;
                            if (field in excelFileBinary.Fields) {
                                if (excelFileBinary.Fields[field].Values.length > 0) {
                                    const foundField = excelData.fieldNames.find(
                                        (f) => f.FieldName == field,
                                    );
                                    let curColSize = Math.max(
                                        1,
                                        (foundField?.DisplayName ?? "").length,
                                    );
                                    data.push([
                                        foundField?.DisplayName,
                                        ...excelFileBinary.Fields[field].Values.map((v: any) => {
                                            let res: String = "";
                                            const fieldSetting =
                                                field in fields
                                                    ? fields[field].Settings.FieldType
                                                    : EFieldSettingsFieldTypes.default;
                                            if (fieldSetting == EFieldSettingsFieldTypes.datetime)
                                                res = ServerStrToLocalDateTimeStr(v);
                                            else if (fieldSetting == EFieldSettingsFieldTypes.date)
                                                res = ServerStrToLocalDateStr(v);
                                            else res = v.toString();

                                            if (curColSize < res.length) curColSize = res.length;

                                            return res;
                                        }),
                                    ]);
                                    colSize.push(curColSize);
                                }
                            }
                        }

                        createExcelFile(excelData.fileName, excelData.sheetName, data, colSize);
                    })
                    .catch((r) => advcatch(r));
            },
        [prepareDataFetching, sendCallbackRequestTrans],
    );

    const setCurrentRecordsInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pageInfo: TPageInfo, providerName: string, newRecords: number[]) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                if (
                    tb.get(providerInitStateGlobal(providerID)) == EProviderServerInitState.HasData
                ) {
                    const currRec = tb.get(dataproviderSelectedValueGlobal(providerID));
                    const newRecs = newRecords;
                    if (currRec != undefined && newRecs.length == 0 && currRec.length > 0)
                        newRecs.push(currRec[0]);
                    if (
                        currRec == undefined ||
                        JSON.stringify(currRec) != JSON.stringify(newRecs)
                    ) {
                        tb.set(dataproviderSelectedValueGlobal(providerID), newRecs);
                    }
                }
            },
        [],
    );

    const setEditableInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pageInfo: TPageInfo, providerName: string, isEditable: boolean) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);

                const wasEditable = tb.get(providerEditableGlobal(providerID));
                tb.set(providerEditableGlobal(providerID), isEditable);
                if (!wasEditable && isEditable) {
                    tb.reset(providerEditDataGlobal(providerID));
                }
            },
        [],
    );

    const setEditDataInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (
                pageInfo: TPageInfo,
                providerName: string,
                { key, data, dataArrayIndex }: { key: string; data: any; dataArrayIndex: number },
            ) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                const oldData = tb.get(providerEditDataGlobal(providerID));
                const newData = oldData instanceof DefaultValue ? [] : deepCopy(oldData);
                if (dataArrayIndex < newData.length) {
                    Object.assign(newData[dataArrayIndex], {
                        ...newData[dataArrayIndex],
                        [key]: data,
                    });
                } else {
                    let recIndex = dataArrayIndex;
                    while (recIndex > newData.length) {
                        newData.push({});
                        ++recIndex;
                    }
                    newData.push({ [key]: data });
                }
                tb.set(providerEditDataGlobal(providerID), newData);
            },
        [],
    );

    const addOrRemEditDataInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pageInfo: TPageInfo, providerName: string, doAdd: boolean) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                if (
                    tb.get(providerInitStateGlobal(providerID)) == EProviderServerInitState.HasData
                ) {
                    const oldData = tb.get(providerEditDataGlobal(providerID));
                    const newData = oldData instanceof DefaultValue ? [] : deepCopy(oldData);
                    if (doAdd) {
                        newData.push({});
                    } else {
                        if (newData.length > 1) newData.pop();
                    }
                    tb.set(providerEditDataGlobal(providerID), newData);
                }
            },
        [],
    );

    const addDataInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (
                pageInfo: TPageInfo,
                providerName: string,
                response: IDataProviderRecordResponseData,
            ) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                const curData = tb.get(providerDataGlobal(providerID));
                let dataLen = 0;
                const current = deepCopy(curData);
                for (const key of Object.keys(response.Fields)) {
                    for (const value of response.Fields[key].Values) {
                        current[key].Values.push(value);
                    }
                    dataLen = Math.max(dataLen, response.Fields[key].Values.length);
                }
                tb.set(providerDataGlobal(providerID), current);

                if (response.maxSeqIsEoF) {
                    tb.set(providerDataEOFGlobal(providerID), {
                        isEOF: response.maxSeqIsEoF,
                        seqEOF: response.MaxSequence,
                    });
                }

                const filter = deepCopy(tb.get(dataproviderFilterGlobal(providerID)));
                filter.sortColumns = response.Sorting.map((s) => {
                    const existingCol = filter.sortColumns?.find((col) => col.name == s.Field);
                    return { name: s.Field, desc: s.IsDesc, isConst: existingCol?.isConst };
                });
                filter.filterSelection = response.Filters;
                tb.set(dataproviderFilterGlobal(providerID), filter);

                const curRecord = tb.get(dataproviderSelectedValueGlobal(providerID));
                if (curRecord == undefined) {
                    const provider = tb.get(dictionary.values(providerID));
                    const buildArrayFromDataLen = () => {
                        const res: Array<number> = [];
                        let i = 0;
                        while (i < dataLen) {
                            res.push(i);
                            ++i;
                        }
                        return res;
                    };
                    setCurrentRecordsInternal(tb)(
                        pageInfo,
                        providerName,
                        provider.Get().loadAndSelectAll ? buildArrayFromDataLen() : [0],
                    );
                }
            },
        [setCurrentRecordsInternal],
    );

    const loadDataInternalAfterDataTransInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            function (
                pageInfo: TPageInfo,
                providerName: string,
                lifetimeID: string,
                response: IDataProviderRecordResponseData,
            ) {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                if (lifetimeID == tb.get(dataproviderLifetimeIDs(providerID))) {
                    const curState = tb.get(providerInitStateGlobal(providerID));
                    if (curState == EProviderServerInitState.HasDatastructure) {
                        tb.set(
                            providerInitStateGlobal(providerID),
                            EProviderServerInitState.HasData,
                        );
                    }

                    addDataInternal(tb)(pageInfo, providerName, response);
                }
            },
        [addDataInternal],
    );

    const loadDataInternalAfterDataTrans = useAdvRecoilTransaction(
        loadDataInternalAfterDataTransInternal,
        [loadDataInternalAfterDataTransInternal],
    );

    const loadDataInternalAfterData = useAdvCallback(
        function (
            pageInfo: TPageInfo,
            providerName: string,
            lifetimeID: string,
            response: IDataProviderRecordResponseData,
        ) {
            if (isOrWillMount()) {
                loadDataInternalAfterDataTrans(pageInfo, providerName, lifetimeID, response);
            }
        },
        [isOrWillMount, loadDataInternalAfterDataTrans],
    );

    const checkIfWouldBeEoF = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            function (pageInfo: TPageInfo, providerName: string, at: number) {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                const dataEOF = tb.get(providerDataEOFGlobal(providerID));
                return dataEOF.isEOF || (dataEOF.seqEOF != -1 && at >= dataEOF.seqEOF);
            },
        [],
    );

    const loadDataInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (
                pageInfo: TPageInfo,
                providerName: string,
                lifetimeID: string,
                startAt: number,
                loadCount: number,
            ) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                const curDataLen = tb.get(providerDataLoadLenGlobal(providerID));
                const provider = tb.get(dictionary.values(providerID));
                let newDataLen = startAt + loadCount;
                if (
                    loadCount <= 0 ||
                    provider.Get().loadAndSelectAll ||
                    provider.Get().loadAll === true
                )
                    newDataLen = MAX_DATA_LEN;

                //if (provider.Get().providerName.trim() == "prvTraeger")
                //    console.warn("MSC Server loadDataInternal", {
                //        loadCount,
                //        provider,
                //        curDataLen,
                //        newDataLen,
                //        startAt,
                //    });
                if (
                    newDataLen > curDataLen &&
                    !checkIfWouldBeEoF(tb)(pageInfo, providerName, startAt)
                ) {
                    const realStartAt = curDataLen;
                    const realLoadCount = newDataLen - curDataLen;
                    tb.set(providerDataLoadLenGlobal(providerID), newDataLen);
                    loadDataInternalSocket(tb)(pageInfo, providerName, realStartAt, realLoadCount)
                        .then(
                            loadDataInternalAfterData.bind(
                                null,
                                pageInfo,
                                providerName,
                                lifetimeID,
                            ),
                        )
                        .catch((r) => {
                            advlog("Could not add new data: ", r);
                        });

                    /* TODO:provider Wird das gebraucht, die DetailsList triggered onMissingItem schon lange bevor es für den Nutzer sichtbar ist
                setTimeout(() =>
                {
                    //   if (!checkIfWouldBeEoF(startAt + loadCount))
                    //      void loadData(startAt + loadCount);
                }, 10);*/
                }
            },
        [checkIfWouldBeEoF, loadDataInternalAfterData, loadDataInternalSocket],
    );
    // ### data loading end ###

    const setInitialProviderDataInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (
                pageInfo: TPageInfo,
                providerName: string,
                paramData: IDataFields,
                paramFields: IDataStructureFields,
            ) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                const curData = tb.get(providerDataGlobal(providerID));
                const curFields = tb.get(providerFieldsGlobal(providerID));
                const isProvInit = tb.get(providerInitStateGlobal(providerID));
                const hasChange =
                    JSON.stringify(curData) != JSON.stringify(paramData) ||
                    JSON.stringify(curFields) != JSON.stringify(paramFields) ||
                    isProvInit != EProviderServerInitState.HasDatastructure;
                if (hasChange) {
                    tb.set(providerDataGlobal(providerID), paramData);
                    tb.set(providerFieldsGlobal(providerID), paramFields);
                    let newState = EProviderServerInitState.HasDatastructure;
                    const paramDataKeys = Object.keys(paramData);
                    if (paramDataKeys.length > 0 && paramData[paramDataKeys[0]].Values.length > 0)
                        newState = EProviderServerInitState.HasData;
                    tb.set(providerInitStateGlobal(providerID), newState);

                    const loadDataRequests = tb.get(dataproviderLoadDataRequests(providerID));
                    while (loadDataRequests.length > 0) {
                        const loadReq = loadDataRequests.shift();
                        if (
                            loadReq &&
                            loadReq.lifetimeID == tb.get(dataproviderLifetimeIDs(providerID))
                        ) {
                            //if (loadReq.loadCount > 50 || loadReq.loadCount <= 0)
                            //    console.warn(
                            //        "MSC setInitialProviderDataInternal loadDataInternal",
                            //        loadReq,
                            //    );
                            loadDataInternal(tb)(
                                pageInfo,
                                providerName,
                                loadReq.lifetimeID,
                                loadReq.startAt,
                                loadReq.loadCount,
                            );
                        }
                    }
                    tb.reset(dataproviderLoadDataRequests(providerID));
                }
            },
        [loadDataInternal],
    );

    const setFilterInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (pageInfo: TPageInfo, providerName: string, newFilter: TDataProviderFilter) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                const curFilter = tb.get(dataproviderFilterGlobal(providerID));
                if (JSON.stringify(curFilter) != JSON.stringify(newFilter)) {
                    clearDataInternal(tb)(pageInfo, providerName);
                    tb.set(dataproviderFilterGlobal(providerID), newFilter);
                }
            },
        [clearDataInternal],
    );

    const checkDataproviderCompletenessAfterDataStructureTransInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            function (
                pageInfo: TPageInfo,
                providerName: string,
                lifetimeID: string,
                val: TDataProviderSocketGetStructureAndDataResponse,
            ) {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                if (lifetimeID == tb.get(dataproviderLifetimeIDs(providerID))) {
                    const fieldKeys = Object.keys(val.Structure.Fields);
                    let dataObj: IDataFields = {};
                    for (const fieldKey of fieldKeys) {
                        Object.assign(dataObj, { ...dataObj, [fieldKey]: { Values: [] } });
                    }

                    tb.set(
                        dataproviderFilterOptionsGlobal(providerID),
                        val.Structure.PossibleFilter.filter((p) => p.Name != ""),
                    );

                    // check errors
                    if (val.Structure.Errs != undefined && val.Structure.Errs.length > 0) {
                        val.Structure.Errs.forEach((err) => {
                            const curWarningsAndErrors = tb.get(
                                dataproviderErrorsAndWarnings(providerID),
                            );
                            tb.set(
                                dataproviderErrorsAndWarnings(providerID),
                                curWarningsAndErrors.concat({
                                    type: EDataProviderIssueType.Error,
                                    msg:
                                        "Could initialize provider '" +
                                        providerName +
                                        "' on server: " +
                                        err,
                                }),
                            );
                        });
                    }

                    // prepare filter selections
                    const zwSelection: TAdvFilterSelection = {};
                    for (const filter of val.Structure.PossibleFilter) {
                        if (filter.Name != "") {
                            for (let j = 0; j < filter.Options.length; j++) {
                                if (filter.Options[j].ActiveByDefault) {
                                    Object.assign(zwSelection, {
                                        ...zwSelection,
                                        [filter.Name]: j,
                                    });
                                    break;
                                }
                            }
                        }
                    }

                    const curFilter = tb.get(dataproviderFilterGlobal(providerID));
                    const initialFilter = {
                        filterSelection: zwSelection,
                        filterValues: {},
                        ...curFilter,
                    };
                    tb.set(dataproviderFilterGlobal(providerID), initialFilter);

                    // if dp exists & it's current data is 0 & data was send, then also apply this data
                    const provDef = tb.get(dictionary.values(providerID));
                    if (provDef.IsLoaded()) {
                        const curDataLen = tb.get(providerDataLoadLenGlobal(providerID));
                        if (curDataLen == 0 && val.Data.dataJSON != "") {
                            const parsedData: IDataProviderRecordResponseData = JSON.parse(
                                val.Data.dataJSON,
                            );
                            const fieldKeys = Object.keys(parsedData.Fields);
                            if (fieldKeys.length > 0) {
                                tb.set(providerDataGlobal(providerID), dataObj);
                                tb.set(
                                    providerDataLoadLenGlobal(providerID),
                                    parsedData.Fields[fieldKeys[0]].Values.length,
                                );
                                tb.set(
                                    providerInitStateGlobal(providerID),
                                    EProviderServerInitState.HasData,
                                );
                                addDataInternal(tb)(pageInfo, providerName, parsedData);
                                dataObj = tb.get(providerDataGlobal(providerID));
                            }
                        }
                    }

                    setInitialProviderDataInternal(tb)(pageInfo, providerName, dataObj, {
                        ...val.Structure.Fields,
                    });

                    if (
                        curFilter.filterValues != undefined &&
                        Object.keys(curFilter.filterValues).length > 0
                    ) {
                        //wir haben Filter gesetzt, diese wurden bei der ersten Anfrage nicht mitgesendet
                        //deshalb hier das Neuladen von Daten triggern
                        clearDataInternal(tb)(pageInfo, providerName);
                    }
                }
            },
        [addDataInternal, setInitialProviderDataInternal, clearDataInternal],
    );

    const checkDataproviderCompletenessAfterDataStructureTrans = useAdvRecoilTransaction(
        checkDataproviderCompletenessAfterDataStructureTransInternal,
        [checkDataproviderCompletenessAfterDataStructureTransInternal],
    );

    const checkDataproviderCompletenessAfterDataStructure = useAdvCallback(
        function (
            pageInfo: TPageInfo,
            providerName: string,
            lifetimeID: string,
            val: TDataProviderSocketGetStructureAndDataResponse | undefined,
        ) {
            if (val != undefined && isOrWillMount()) {
                checkDataproviderCompletenessAfterDataStructureTrans(
                    pageInfo,
                    providerName,
                    lifetimeID,
                    val,
                );
            } else if (!isOrWillMount()) {
                advlog("Error dataprovider hook was not mounted anymore");
            }
        },
        [checkDataproviderCompletenessAfterDataStructureTrans, isOrWillMount],
    );

    const checkDataproviderCompletenessAfterIDTransInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            function (
                pageInfo: TPageInfo,
                providerName: string,
                lifetimeID: string,
                resSuccess: boolean,
            ) {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                if (!resSuccess) {
                    const curWarningsAndErrors = tb.get(dataproviderErrorsAndWarnings(providerID));
                    tb.set(
                        dataproviderErrorsAndWarnings(providerID),
                        curWarningsAndErrors.concat({
                            type: EDataProviderIssueType.Error,
                            msg: "Could initialize provider: '" + providerName + "'",
                        }),
                    );
                } else if (lifetimeID == tb.get(dataproviderLifetimeIDs(providerID))) {
                    loadDataStructureInternalSocket(tb)(
                        pageInfo,
                        providerName,
                        lifetimeID,
                        checkDataproviderCompletenessAfterDataStructure.bind(
                            null,
                            pageInfo,
                            providerName,
                        ),
                    );
                }
            },
        [checkDataproviderCompletenessAfterDataStructure, loadDataStructureInternalSocket],
    );

    const checkDataproviderCompletenessAfterIDTrans = useAdvRecoilTransaction(
        checkDataproviderCompletenessAfterIDTransInternal,
        [checkDataproviderCompletenessAfterIDTransInternal],
    );

    const checkDataproviderCompletenessAfterID = useAdvCallback(
        function (
            pageInfo: TPageInfo,
            providerName: string,
            lifetimeID: string,
            resSuccess: boolean,
        ) {
            if (isOrWillMount()) {
                checkDataproviderCompletenessAfterIDTrans(
                    pageInfo,
                    providerName,
                    lifetimeID,
                    resSuccess,
                );
            } else {
                advlog("Error dataprovider hook was not mounted anymore");
            }
        },
        [checkDataproviderCompletenessAfterIDTrans, isOrWillMount],
    );

    const checkDataproviderCompleteness = useAdvCallback(
        (tb: TAdvTransactionInterface) => (pageInfo: TPageInfo, providerName: string) => {
            const providerID = buildUniqueProviderID(pageInfo, providerName);
            initDataproviderInternalSocket(tb)(
                pageInfo,
                providerName,
                checkDataproviderCompletenessAfterID.bind(
                    null,
                    pageInfo,
                    providerName,
                    tb.get(dataproviderLifetimeIDs(providerID)),
                ),
                checkDataproviderCompletenessAfterIDTransInternal,
            );
        },
        [
            checkDataproviderCompletenessAfterID,
            checkDataproviderCompletenessAfterIDTransInternal,
            initDataproviderInternalSocket,
        ],
    );

    // ### Event handling ###
    const eventTransactionInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => () => {
            const curEvents = tb.get(dataproviderEvents);
            // go through all events
            const handleEvents = curEvents.filter((ev) => {
                const isDesignerEv = ev.pageInfo.pathname.startsWith("/designer/");
                return (isDesignerEv && isDesigner) || (!isDesignerEv && !isDesigner);
            });
            const remainingEvents = curEvents.filter((ev) => {
                const isDesignerEv = ev.pageInfo.pathname.startsWith("/designer/");
                return (!isDesignerEv && isDesigner) || (isDesignerEv && !isDesigner);
            });
            for (const curEvent of handleEvents) {
                const eventType = curEvent.event;
                const eventData = curEvent.eventData;
                const pageInfo = curEvent.pageInfo;
                const dataproviderName = curEvent.providerName;
                const lifetimeID = curEvent.lifetimeID;
                const providerID = buildUniqueProviderID(pageInfo, dataproviderName);
                const providerLifetimeID = tb.get(dataproviderLifetimeIDs(providerID));
                const provInitState = tb.get(providerInitStateGlobal(providerID));
                const provider = tb.get(dictionary.values(providerID));
                // also check here if event is still allowed to pass, tho it's mostly useful for promises
                if (lifetimeID == providerLifetimeID && provider.IsLoaded()) {
                    switch (eventType) {
                        case EDataproviderEvent.Reset:
                            {
                                resetInternal(tb)(pageInfo, dataproviderName, provider.Get());
                            }
                            break;
                        case EDataproviderEvent.Reload:
                            {
                                sendCallbackRequestTrans(tb)(
                                    (data: TSuccessClass) => {
                                        if (data.Success) {
                                            reset(
                                                pageInfo,
                                                dataproviderName,
                                                provider.Get(),
                                                false,
                                            );
                                        }
                                    },
                                    "dataprovider",
                                    "reset",
                                    {
                                        ProviderName: dataproviderName,
                                    },
                                );
                            }
                            break;
                        case EDataproviderEvent.ResetSort:
                            {
                                sendCallbackRequestTrans(tb)(
                                    (data: TSuccessClass) => {
                                        if (data.Success) {
                                            resetSort(pageInfo, dataproviderName, provider.Get());
                                        }
                                    },
                                    "dataprovider",
                                    "reset_sort",
                                    {
                                        ProviderName: dataproviderName,
                                    },
                                );
                            }
                            break;
                        case EDataproviderEvent.ClearData:
                            {
                                clearDataInternal(tb)(pageInfo, dataproviderName);
                            }
                            break;
                        case EDataproviderEvent.CheckInit:
                            {
                                checkDataproviderCompleteness(tb)(pageInfo, dataproviderName);
                            }
                            break;
                        case EDataproviderEvent.SetFilter:
                            {
                                setFilterInternal(tb)(pageInfo, dataproviderName, eventData);
                            }
                            break;
                        case EDataproviderEvent.LoadData:
                            {
                                const evDataLoad = eventData as {
                                    startAt: number;
                                    loadCount: number;
                                };
                                const curEvents = tb.get(dataproviderLoadDataRequests(providerID));
                                if (
                                    [
                                        EProviderServerInitState.HasDatastructure,
                                        EProviderServerInitState.HasData,
                                    ].includes(provInitState)
                                ) {
                                    //if (evDataLoad.loadCount > 50 || evDataLoad.loadCount <= 0)
                                    //    console.warn(
                                    //        "MSC eventTransactionInternal 1111 loadDataInternal",
                                    //        evDataLoad,
                                    //        dataproviderName,
                                    //    );
                                    loadDataInternal(tb)(
                                        pageInfo,
                                        dataproviderName,
                                        providerLifetimeID,
                                        evDataLoad.startAt,
                                        evDataLoad.loadCount,
                                    );
                                } else {
                                    if (
                                        curEvents.find(
                                            (val) =>
                                                val.lifetimeID == providerLifetimeID &&
                                                val.startAt == evDataLoad.startAt &&
                                                val.loadCount == evDataLoad.loadCount,
                                        ) == undefined
                                    ) {
                                        //if (evDataLoad.loadCount > 50 || evDataLoad.loadCount <= 0)
                                        //    console.warn(
                                        //        "MSC eventTransactionInternal 222 loadDataInternal",
                                        //        evDataLoad,
                                        //        dataproviderName,
                                        //    );
                                        tb.set(
                                            dataproviderLoadDataRequests(providerID),
                                            curEvents.concat({
                                                lifetimeID: providerLifetimeID,
                                                startAt: evDataLoad.startAt,
                                                loadCount: evDataLoad.loadCount,
                                            }),
                                        );
                                    }
                                    checkDataproviderCompleteness(tb)(pageInfo, dataproviderName);
                                }
                            }
                            break;
                        case EDataproviderEvent.SetRecords:
                            {
                                const evData = eventData as number[];
                                setCurrentRecordsInternal(tb)(pageInfo, dataproviderName, evData);
                            }
                            break;
                        case EDataproviderEvent.Editable:
                            {
                                const isEditable = eventData as boolean;
                                setEditableInternal(tb)(pageInfo, dataproviderName, isEditable);
                            }
                            break;
                        case EDataproviderEvent.SetEditData:
                            {
                                const editData = eventData as {
                                    key: string;
                                    data: any;
                                    dataArrayIndex: number;
                                };
                                setEditDataInternal(tb)(pageInfo, dataproviderName, editData);
                            }
                            break;
                        case EDataproviderEvent.AddOrRemEditData:
                            {
                                const shouldAdd = (
                                    eventData as {
                                        doAdd: boolean;
                                    }
                                ).doAdd;
                                addOrRemEditDataInternal(tb)(pageInfo, dataproviderName, shouldAdd);
                            }
                            break;
                        case EDataproviderEvent.GetExcel:
                            {
                                const excelData = eventData as TExcelExportData;
                                loadExcelData(tb)(pageInfo, dataproviderName, excelData);
                            }
                            break;
                        default:
                            trans_assert(false, "unsupported event triggered.");
                            break;
                    }
                }
            }
            tb.set(dataproviderEvents, remainingEvents);
        },
        [
            addOrRemEditDataInternal,
            checkDataproviderCompleteness,
            clearDataInternal,
            isDesigner,
            loadDataInternal,
            loadExcelData,
            reset,
            resetInternal,
            resetSort,
            sendCallbackRequestTrans,
            setCurrentRecordsInternal,
            setEditDataInternal,
            setEditableInternal,
            setFilterInternal,
        ],
    );

    const eventTransaction = useAdvRecoilTransaction(eventTransactionInternal, [
        eventTransactionInternal,
    ]);

    const timeoutID = useRef<any>(-1);
    useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
        const curEvs = snapshot.getLoadable(dataproviderEvents).contents;
        if (timeoutID.current != -1) {
            clearTimeout(timeoutID.current);
            timeoutID.current = -1;
        }
        if (curEvs.length > 0) {
            // always set a very small delay, for most stuff it doesn't matter and we want as much batching as possible
            timeoutID.current = setTimeout(() => eventTransaction(), 0);
        }
    });
};

export const useProvider = () => {
    const { sendCallbackRequestTrans } = useAdvSocketCallback();

    const resetInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (
                pageInfo: TPageInfo,
                providerName: string,
                provider: IAdvDataProvider,
                shouldResetFilter: boolean = true,
                shouldResetSearch: boolean = true,
            ) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                tb.reset(providerInitStateGlobal(providerID));
                tb.reset(providerDataGlobal(providerID));
                tb.reset(providerEditableGlobal(providerID));
                tb.reset(providerEditDataGlobal(providerID));
                tb.reset(providerDataEOFGlobal(providerID));
                tb.reset(providerDataLoadLenGlobal(providerID));
                tb.reset(providerFieldsGlobal(providerID));
                if (shouldResetFilter)
                    tb.set(dataproviderFilterGlobal(providerID), provider.defaultFilter ?? {});
                else if (shouldResetSearch) {
                    tb.set(dataproviderFilterGlobal(providerID), {
                        ...deepCopy(tb.get(dataproviderFilterGlobal(providerID))),
                        filteredText: "",
                    });
                }
                tb.reset(dataproviderFilterOptionsGlobal(providerID));
                tb.reset(dataproviderSelectedValueGlobal(providerID));
                tb.reset(dataproviderLoadDataRequests(providerID));
                tb.reset(dataproviderErrorsAndWarnings(providerID));
                // don't allow old request to pass
                tb.set(dataproviderLifetimeIDs(providerID), nanoid());
            },
        [],
    );
    const reset = useAdvRecoilTransaction(resetInternal, [resetInternal]);

    const reloadAllDataproviderInternal =
        (tb: TAdvTransactionInterface) => (pageInfo: TPageInfo) => {
            const pageIDProv = buildPageIDForProviderID(pageInfo);
            const keys = tb.get(providersOfPage(pageIDProv));
            for (const key of keys) {
                const provID = buildUniqueProviderID(pageInfo, key);
                const provider = tb.get(dictionary.values(provID));

                sendCallbackRequestTrans(tb)(
                    (data: TSuccessClass) => {
                        if (data.Success) {
                            reset(pageInfo, key, provider.Get(), false);
                        }
                    },
                    "dataprovider",
                    "reset",
                    {
                        ProviderName: key,
                    },
                );
            }
            const provEvents = tb.get(dataproviderEvents);
            const newEvents = provEvents.filter(
                (val) => JSON.stringify(val.pageInfo) == JSON.stringify(pageInfo),
            );
            tb.set(dataproviderEvents, newEvents);
            tb.set(providersOfPage(pageIDProv), []);
        };

    const reloadAllDataprovider = useAdvRecoilTransaction(reloadAllDataproviderInternal, [
        reloadAllDataproviderInternal,
    ]);

    return { resetInternal, reset, reloadAllDataprovider };
};

const providersOfPage = atomFamily<string[], string>({
    key: "providersOfPageAtomFam",
    default: [],
});

const addOrSetDataproviderData =
    (tb: TAdvTransactionInterface) =>
    (
        providerID: TProviderID,
        { data, compareField }: { data: Record<string, any>; compareField: string },
    ) => {
        const curData = tb.get(providerDataGlobal(providerID));
        const foundValIndex = curData[compareField].Values.findIndex(
            (val) => val == data[compareField],
        );
        // if not found add, else set
        let dataCopy = deepCopy(curData);
        const dataKeys = Object.keys(dataCopy);
        if (foundValIndex == -1) {
            dataKeys.forEach((key) => {
                dataCopy[key].Values.push(data[key]);
            });
        } else {
            dataKeys.forEach((key) => {
                dataCopy[key].Values[foundValIndex] = data[key];
            });
        }
        const curFilter = tb.get(dataproviderFilterGlobal(providerID));
        // only try sorting if no sorting was used which makes it hard to sort client side
        if (
            (curFilter.filterSelection == undefined ||
                Object.keys(curFilter.filterSelection).length == 0) &&
            curFilter.customFilteredFields == undefined &&
            (curFilter.filterValues == undefined ||
                Object.keys(curFilter.filterValues).length == 0) &&
            (curFilter.filteredText != undefined || curFilter.sortColumns != undefined)
        ) {
            let allDataArray: any[] = [];
            if (dataKeys.length > 0) {
                // since sorting and filtering changes the indices, get the current selected items
                const surSelIndices = tb.get(dataproviderSelectedValueGlobal(providerID));
                const curSelRecords: string[] = [];
                surSelIndices.forEach((valI) => {
                    const selAsObj = {};
                    dataKeys.forEach((valK) => {
                        if (valI < dataCopy[valK].Values.length) {
                            Object.assign(selAsObj, {
                                ...selAsObj,
                                [valK]: dataCopy[valK].Values[valI],
                            });
                        }
                    });
                    curSelRecords.push(JSON.stringify(selAsObj));
                });
                let i = 0;
                while (i < dataCopy[dataKeys[0]].Values.length) {
                    allDataArray.push({});
                    ++i;
                }
                dataKeys.forEach((key) => {
                    dataCopy[key].Values.forEach((val, index) => {
                        Object.assign(allDataArray[index], {
                            ...allDataArray[index],
                            [key]: val,
                        });
                    });
                });
                if (curFilter.filteredText != undefined) {
                    const searchTexts = [curFilter.filteredText];
                    allDataArray = allDataArray.filter((val) => {
                        const foundItem = dataKeys.find((key) => {
                            return searchTexts.find(
                                (valS) =>
                                    JSON.stringify(valS)
                                        .toLowerCase()
                                        .localeCompare(JSON.stringify(val[key]).toLowerCase()) == 0,
                            );
                        });
                        return foundItem != undefined;
                    });
                }
                if (curFilter.sortColumns != undefined) {
                    allDataArray = _copyAndSort(
                        allDataArray,
                        curFilter.sortColumns.map((val) => {
                            return { columnKey: val.name, isSortedDescending: val.desc };
                        }),
                    );
                }
                dataCopy = {};
                allDataArray.forEach((val) => {
                    const valKeys = Object.keys(val);
                    valKeys.forEach((valK) => {
                        if (typeof dataCopy[valK] == "undefined") {
                            Object.assign(dataCopy, {
                                ...dataCopy,
                                [valK]: { Values: [val[valK]] },
                            });
                        } else {
                            dataCopy[valK].Values.push(val[valK]);
                        }
                    });
                });

                // rebuild selected indices
                const selectedIndices: number[] = [];
                curSelRecords.forEach((valR) => {
                    const foundIndex = allDataArray.findIndex(
                        (valA) => JSON.stringify(valA) == valR,
                    );
                    if (foundIndex != -1) selectedIndices.push(foundIndex);
                });

                tb.set(dataproviderSelectedValueGlobal(providerID), selectedIndices);
            }
        }
        // Spätestens wenn der DP Daten vom Contract bekommt
        // sollte er in den HasData-State wechseln sofern er noch in HasDatastructure ist
        if (
            dataKeys.length > 0 &&
            tb.get(providerInitStateGlobal(providerID)) == EProviderServerInitState.HasDatastructure
        ) {
            tb.set(providerInitStateGlobal(providerID), EProviderServerInitState.HasData);
        }
        tb.set(providerDataGlobal(providerID), dataCopy);
    };

const removeDataproviderData =
    (tb: TAdvTransactionInterface) =>
    (
        providerID: TProviderID,
        { compareData, compareField }: { compareData: any; compareField: string },
    ) => {
        const curData = tb.get(providerDataGlobal(providerID));
        const foundValIndex = curData[compareField].Values.findIndex((val) => val == compareData);
        const dataCopy = deepCopy(curData);
        if (foundValIndex != -1) {
            const dataKeys = Object.keys(dataCopy);
            dataKeys.forEach((key) => {
                dataCopy[key].Values.splice(foundValIndex, 1);
            });
        }
        tb.set(providerDataGlobal(providerID), dataCopy);
    };

const resetAndRemoveAllDataprovider = (tb: TAdvTransactionInterface) => (pageInfo: TPageInfo) => {
    const pageIDProv = buildPageIDForProviderID(pageInfo);
    const keys = tb.get(providersOfPage(pageIDProv));
    for (const key of keys) {
        const provID = buildUniqueProviderID(pageInfo, key);
        tb.reset(providerInitStateGlobal(provID));
        tb.reset(providerDataGlobal(provID));
        tb.reset(providerEditableGlobal(provID));
        tb.reset(providerEditDataGlobal(provID));
        tb.reset(providerDataEOFGlobal(provID));
        tb.reset(providerDataLoadLenGlobal(provID));
        tb.reset(providerFieldsGlobal(provID));
        tb.reset(dataproviderFilterGlobal(provID));
        tb.reset(dataproviderFilterOptionsGlobal(provID));
        tb.reset(dataproviderSelectedValueGlobal(provID));
        tb.reset(dataproviderErrorsAndWarnings(provID));
        tb.set(dataproviderLifetimeIDs(provID), nanoid());
        tb.reset(dataproviderLoadDataRequests(provID));
        dictionary.removeItem(tb)(provID);
    }
    const provEvents = tb.get(dataproviderEvents);
    const newEvents = provEvents.filter(
        (val) => JSON.stringify(val.pageInfo) == JSON.stringify(pageInfo),
    );
    tb.set(dataproviderEvents, newEvents);
    tb.set(providersOfPage(pageIDProv), []);
};

const addOrSetDataproviderDefinitionInternally =
    (tb: TAdvTransactionInterface) =>
    (key: TProviderID, itemToAddOrSet: IAdvDataProvider, resetAllStates: boolean = true) => {
        trans_assert(itemToAddOrSet != undefined);
        const pageIDProv = key.pageid;
        const keys = deepCopy(tb.get(providersOfPage(pageIDProv)));
        if (!keys.includes(key.name)) keys.push(key.name);
        tb.set(providersOfPage(pageIDProv), keys);
        dictionary.addOrSetItem(tb)(key, itemToAddOrSet);
        if (resetAllStates) {
            // also reset all states for this provider then
            tb.reset(providerInitStateGlobal(key));
            tb.reset(providerDataGlobal(key));
            tb.reset(providerEditableGlobal(key));
            tb.reset(providerEditDataGlobal(key));
            tb.reset(providerDataEOFGlobal(key));
            tb.reset(providerDataLoadLenGlobal(key));
            tb.reset(providerFieldsGlobal(key));
            tb.set(
                dataproviderFilterGlobal(key),
                itemToAddOrSet.defaultFilter != undefined
                    ? deepCopy(itemToAddOrSet.defaultFilter)
                    : {},
            );
            tb.reset(dataproviderFilterOptionsGlobal(key));
            tb.reset(dataproviderSelectedValueGlobal(key));
            tb.reset(dataproviderErrorsAndWarnings(key));
            tb.set(dataproviderLifetimeIDs(key), nanoid());
            tb.reset(dataproviderLoadDataRequests(key));
        }
    };

const removeDataproviderDefinitionInternally =
    (tb: TAdvTransactionInterface) => (key: TProviderID) => {
        const pageIDProv = key.pageid;
        const keys = deepCopy(tb.get(providersOfPage(pageIDProv))).filter((val) => val != key.name);
        tb.set(providersOfPage(pageIDProv), keys);
        dictionary.removeItem(tb)(key);
        // also reset all states for this provider then
        tb.reset(providerInitStateGlobal(key));
        tb.reset(providerDataGlobal(key));
        tb.reset(providerEditableGlobal(key));
        tb.reset(providerEditDataGlobal(key));
        tb.reset(providerDataEOFGlobal(key));
        tb.reset(providerDataLoadLenGlobal(key));
        tb.reset(providerFieldsGlobal(key));
        tb.reset(dataproviderFilterGlobal(key));
        tb.reset(dataproviderFilterOptionsGlobal(key));
        tb.reset(dataproviderSelectedValueGlobal(key));
        tb.reset(dataproviderErrorsAndWarnings(key));
        tb.set(dataproviderLifetimeIDs(key), nanoid());
        tb.reset(dataproviderLoadDataRequests(key));
    };

/**
 * Ein Object, dass serverseitige Recoils (Atome oder Selectors etc.) bereitstellt.
 * Generell sollte dieses Object nicht direkt benutzt werden, sondern der @see useDataproviderServer Hook.
 */
export const recoilDataProviderServer = {
    dataproviderNamesOfPage: (param: string): RecoilValueReadOnly<Array<string>> =>
        providersOfPage(param),
    dataproviders: selectorFamily<IAdvDataProvider[], { pageInfo: TPageInfo }>({
        key: "dataproviders",
        get:
            (param) =>
            ({ get }) => {
                const pageIDProv = buildPageIDForProviderID(param.pageInfo);
                const temp: IAdvDataProvider[] = [];
                const dpKeyNames = get(providersOfPage(pageIDProv));
                for (const dpName of dpKeyNames) {
                    const provID = buildUniqueProviderID(param.pageInfo, dpName);
                    const dp = get(dictionary.values(provID));
                    temp.push(dp.Get());
                }
                return temp;
            },
    }),
    moveDpToEnd:
        (tb: TAdvTransactionInterface) => (param: { pageInfo: TPageInfo; dpName: string }) => {
            const pageIDProv = buildPageIDForProviderID(param.pageInfo);
            const dpKeyNames = deepCopy(tb.get(providersOfPage(pageIDProv)));
            const removed = dpKeyNames.splice(dpKeyNames.indexOf(param.dpName), 1);
            dpKeyNames.push(removed[0]);
            tb.set(providersOfPage(pageIDProv), dpKeyNames);
        },
    getDataproviderDefinition: dictionary.values,
    addOrSetDataproviderDefinition: addOrSetDataproviderDefinitionInternally,
    removeDataproviderDefinition: removeDataproviderDefinitionInternally,
    resetAndRemoveAllDataprovider,

    addOrSetDataproviderData,
    removeDataproviderData,
};

/**
 * Ein Object, dass clientseitige Recoils (Atome oder Selectors etc.) bereitstellt.
 * Generell sollte dieses Object nicht direkt benutzt werden, sondern der @see useDataprovider Hook.
 */
export const recoilDataProviderClient = {
    providerInitStateGlobal,
    providerDataGlobal,
    providerEditableGlobal,
    providerDataEOFGlobal,
    providerDataLoadLenGlobal,
    providerFieldsGlobal,
    dataproviderFilterGlobal,
    dataproviderFilterOptionsGlobal,
    dataproviderErrorsAndWarnings,
    dataproviderEvents,
    dataproviderLifetimeIDs,
    dataproviderLoadDataRequests,
    getDataproviderDefinition: dictionary.values,
};

/** Dieser RecoilState stellt alle ausgewählten Datensätze von einer Liste von Dataprovidern zur Verfügung */
export const dataproviderSelectedKeys = selectorFamily({
    key: "dataproviderSelectedKeys",
    get:
        (provNameList: TProviderID[]) =>
        ({ get }) => {
            const temp: any[][] = [];
            for (const provName of provNameList) {
                const providerRecords = get(dataproviderSelectedValueGlobal(provName));
                if (providerRecords != undefined) {
                    const data = get(providerDataGlobal(provName));
                    const fields = get(providerFieldsGlobal(provName));
                    const dataKeys = Object.keys(data);
                    const recordList: any[] = [];
                    for (const aRecord of providerRecords) {
                        if (dataKeys.length > 0 && data[dataKeys[0]].Values.length > aRecord) {
                            const selectedRecord = {};
                            for (const dataKey of dataKeys) {
                                Object.assign(selectedRecord, {
                                    ...selectedRecord,
                                    [dataKey]: data[dataKey].Values[aRecord],
                                });
                            }
                            Object.assign(selectedRecord, {
                                ...selectedRecord,
                                [gDataproviderRecordIndexName]: aRecord,
                            });
                            recordList.push(selectedRecord);
                        }
                    }
                    const isEditable = get(providerEditableGlobal(provName));
                    const editData = get(providerEditDataGlobal(provName));
                    if (isEditable && editData.length > recordList.length) {
                        // add new records to our list so edit data can fill them
                        while (editData.length > recordList.length) {
                            const addObj: Record<string, any> = {};
                            for (const fieldKey in fields) {
                                addObj[fieldKey] = "";
                            }
                            recordList.push(addObj);
                        }
                    }
                    if (recordList.length > 0 && isEditable) {
                        recordList.forEach((valP, indexP) => {
                            if (indexP < editData.length) {
                                const recKeys = Object.keys(valP);
                                const editKeys = Object.keys(editData[indexP]);
                                editKeys.forEach((valE) => {
                                    if (recKeys.includes(valE)) {
                                        valP[valE] = editData[indexP][valE];
                                    }
                                });
                            }
                        });
                    }
                    temp.push(recordList);
                }
            }
            return temp;
        },
});

/** Dieser RecoilState stellt alle Auswahlmöglichkeiten für das ProviderKey-Dropdown im UI-Designer zur Verfügung */
export const providerKeyArray = selectorFamily<TAdvDropdownItem<any>[], { pageInfo: TPageInfo }>({
    key: "providerKeyArray",
    get:
        (param) =>
        ({ get }) => {
            const temp: TAdvDropdownItem[] = [];
            const providers = get(
                recoilDataProviderServer.dataproviders({
                    pageInfo: param.pageInfo,
                }),
            );
            for (const provider of providers) {
                const dropdownItem = {
                    key: `ddi_${provider.providerKey}`,
                    text:
                        replaceDatasourcePrefix(provider.datasourceName) +
                        " [" +
                        provider.providerName +
                        "]",
                    data: provider.providerKey,
                } as TAdvDropdownItem<any>;
                temp.push(dropdownItem);
            }
            return temp;
        },
});

/** Dieser RecoilState stellt alle Auswahlmöglichkeiten für die Provider-Felder als Dropdown im UI-Designer zur Verfügung */
export const providerFieldsArray = selectorFamily({
    key: "providerFieldsArray",
    get:
        (provNameParam: TProviderID) =>
        ({ get }) => {
            const temp: TAdvDropdownItem<any>[] = [];
            const providerToVal = get(providerFieldsGlobal(provNameParam));
            const fieldKeys = Object.keys(providerToVal).sort(
                (val1, val2) => providerToVal[val1].ColumnIndex - providerToVal[val2].ColumnIndex,
            );
            for (const field of fieldKeys) {
                const dropdownItem = {
                    key: `ddi_${field}`,
                    text: field,
                    data: field,
                } as TAdvDropdownItem<any>;
                temp.push(dropdownItem);
            }

            return temp;
        },
});

export const providerAllOfPage = selectorFamily<
    { key: string; fields: string[] }[],
    { pageInfo: TPageInfo }
>({
    key: "providerAllOfPage",
    get:
        ({ pageInfo }) =>
        ({ get }) => {
            const res: { key: string; fields: string[] }[] = [];
            const allKeys = get(providerKeyArray({ pageInfo }));
            for (const key of allKeys) {
                const fields: string[] = [];

                const fieldsTmp = get(
                    providerFieldsArray(buildUniqueProviderID(pageInfo, key.data)),
                );

                for (const fieldTmp of fieldsTmp) fields.push(fieldTmp.data);

                res.push({ key: key.data, fields });
            }
            return res;
        },
});

// only useful for e.g. benchmarking
export const providersOfPageLoaded = selectorFamily<boolean, { pageInfo: TPageInfo }>({
    key: "providersOfPageLoaded",
    get:
        ({ pageInfo }) =>
        ({ get }) => {
            const allKeys = get(providerKeyArray({ pageInfo }));
            for (const key of allKeys) {
                const initState = get(
                    providerInitStateGlobal(buildUniqueProviderID(pageInfo, key.data)),
                );

                if (initState != EProviderServerInitState.HasData) return false;
            }
            return true;
        },
});
